function getLocaleDateString (lang) {
  const formats = {
    'af': 'yyyy/MM/dd',
    'af-za': 'yyyy/MM/dd',
    'am': 'd/M/yyyy',
    'am-et': 'd/M/yyyy',
    'ar': 'dd/MM/yyyy',
    'ar-ae': 'dd/MM/yyyy',
    'ar-bh': 'dd/MM/yyyy',
    'ar-dz': 'dd-MM-yyyy',
    'ar-eg': 'dd/MM/yyyy',
    'ar-iq': 'dd/MM/yyyy',
    'ar-jo': 'dd/MM/yyyy',
    'ar-kw': 'dd/MM/yyyy',
    'ar-lb': 'dd/MM/yyyy',
    'ar-ly': 'dd/MM/yyyy',
    'ar-ma': 'dd-MM-yyyy',
    'ar-om': 'dd/MM/yyyy',
    'ar-qa': 'dd/MM/yyyy',
    'ar-sa': 'dd/MM/yy',
    'ar-sy': 'dd/MM/yyyy',
    'ar-tn': 'dd-MM-yyyy',
    'ar-ye': 'dd/MM/yyyy',
    'arn': 'dd-MM-yyyy',
    'arn-cl': 'dd-MM-yyyy',
    'as': 'dd-MM-yyyy',
    'as-in': 'dd-MM-yyyy',
    'az': 'dd.MM.yyyy',
    'az-cyrl-az': 'dd.MM.yyyy',
    'az-latn-az': 'dd.MM.yyyy',
    'ba': 'dd.MM.yy',
    'ba-ru': 'dd.MM.yy',
    'be': 'dd.MM.yyyy',
    'be-by': 'dd.MM.yyyy',
    'bg': 'dd.M.yyyy',
    'bg-bg': 'dd.M.yyyy',
    'bn': 'dd-MM-yy',
    'bn-bd': 'dd-MM-yy',
    'bn-in': 'dd-MM-yy',
    'bo': 'yyyy/M/d',
    'bo-cn': 'yyyy/M/d',
    'br': 'dd/MM/yyyy',
    'br-fr': 'dd/MM/yyyy',
    'bs': 'd.M.yyyy',
    'bs-cyrl-ba': 'd.M.yyyy',
    'bs-latn-ba': 'd.M.yyyy',
    'ca': 'dd/MM/yyyy',
    'ca-es': 'dd/MM/yyyy',
    'co': 'dd/MM/yyyy',
    'co-fr': 'dd/MM/yyyy',
    'cs': 'd.M.yyyy',
    'cs-cz': 'd.M.yyyy',
    'cy': 'dd/MM/yyyy',
    'cy-gb': 'dd/MM/yyyy',
    'da': 'dd-MM-yyyy',
    'da-dk': 'dd-MM-yyyy',
    'de': 'dd.MM.yyyy',
    'de-at': 'dd.MM.yyyy',
    'de-ch': 'dd.MM.yyyy',
    'de-de': 'dd.MM.yyyy',
    'de-li': 'dd.MM.yyyy',
    'de-lu': 'dd.MM.yyyy',
    'dsb': 'd. M. yyyy',
    'dsb-de': 'd. M. yyyy',
    'dv': 'dd/MM/yy',
    'dv-mv': 'dd/MM/yy',
    'el': 'd/M/yyyy',
    'el-gr': 'd/M/yyyy',
    'en': 'dd/MM/yyyy',
    'en-029': 'MM/dd/yyyy',
    'en-au': 'd/MM/yyyy',
    'en-bz': 'dd/MM/yyyy',
    'en-ca': 'yyyy-MM-dd',
    'en-gb': 'dd/MM/yyyy',
    'en-ie': 'dd/MM/yyyy',
    'en-in': 'dd-MM-yyyy',
    'en-jm': 'dd/MM/yyyy',
    'en-my': 'd/M/yyyy',
    'en-nz': 'd/MM/yyyy',
    'en-ph': 'M/d/yyyy',
    'en-sh': 'd/M/yyyy',
    'en-tt': 'dd/MM/yyyy',
    'en-us': 'M/d/yyyy',
    'en-US': 'M/d/yyyy',
    'en-za': 'yyyy/MM/dd',
    'en-zw': 'M/d/yyyy',
    'es': 'dd/MM/yyyy',
    'es-ar': 'dd/MM/yyyy',
    'es-bo': 'dd/MM/yyyy',
    'es-cl': 'dd-MM-yyyy',
    'es-co': 'dd/MM/yyyy',
    'es-cr': 'dd/MM/yyyy',
    'es-do': 'dd/MM/yyyy',
    'es-ec': 'dd/MM/yyyy',
    'es-es': 'dd/MM/yyyy',
    'es-gt': 'dd/MM/yyyy',
    'es-hn': 'dd/MM/yyyy',
    'es-mx': 'dd/MM/yyyy',
    'es-ni': 'dd/MM/yyyy',
    'es-pa': 'MM/dd/yyyy',
    'es-pe': 'dd/MM/yyyy',
    'es-pr': 'dd/MM/yyyy',
    'es-py': 'dd/MM/yyyy',
    'es-sv': 'dd/MM/yyyy',
    'es-us': 'M/d/yyyy',
    'es-uy': 'dd/MM/yyyy',
    'es-ve': 'dd/MM/yyyy',
    'et': 'd.MM.yyyy',
    'et-ee': 'd.MM.yyyy',
    'eu': 'yyyy/MM/dd',
    'eu-es': 'yyyy/MM/dd',
    'fa': 'MM/dd/yyyy',
    'fa-ir': 'MM/dd/yyyy',
    'fi': 'd.M.yyyy',
    'fi-fi': 'd.M.yyyy',
    'fil': 'M/d/yyyy',
    'fil-ph': 'M/d/yyyy',
    'fo': 'dd-MM-yyyy',
    'fo-fo': 'dd-MM-yyyy',
    'fr': 'd/MM/yyyy',
    'fr-be': 'd/MM/yyyy',
    'fr-ca': 'yyyy-MM-dd',
    'fr-ch': 'dd.MM.yyyy',
    'fr-fr': 'dd/MM/yyyy',
    'fr-lu': 'dd/MM/yyyy',
    'fr-mc': 'dd/MM/yyyy',
    'fy': 'd-M-yyyy',
    'fy-nl': 'd-M-yyyy',
    'ga': 'dd/MM/yyyy',
    'ga-ie': 'dd/MM/yyyy',
    'gd': 'dd/MM/yyyy',
    'gd-gb': 'dd/MM/yyyy',
    'gl': 'dd/MM/yy',
    'gl-es': 'dd/MM/yy',
    'gsw': 'dd/MM/yyyy',
    'gsw-fr': 'dd/MM/yyyy',
    'gu': 'dd-MM-yy',
    'gu-in': 'dd-MM-yy',
    'ha': 'd/M/yyyy',
    'ha-latn-ng': 'd/M/yyyy',
    'he': 'dd/MM/yyyy',
    'he-il': 'dd/MM/yyyy',
    'hi': 'dd-MM-yyyy',
    'hi-in': 'dd-MM-yyyy',
    'hr': 'd.M.yyyy.',
    'hr-ba': 'd.M.yyyy.',
    'hr-hr': 'd.M.yyyy',
    'hsb': 'd. M. yyyy',
    'hsb-de': 'd. M. yyyy',
    'hu': 'yyyy. MM. dd.',
    'hu-hu': 'yyyy. MM. dd.',
    'hy': 'dd.MM.yyyy',
    'hy-am': 'dd.MM.yyyy',
    'id': 'dd/MM/yyyy',
    'id-id': 'dd/MM/yyyy',
    'ig': 'd/M/yyyy',
    'ig-ng': 'd/M/yyyy',
    'ii': 'yyyy/M/d',
    'ii-cn': 'yyyy/M/d',
    'is': 'd.M.yyyy',
    'is-is': 'd.M.yyyy',
    'it': 'dd.MM.yyyy',
    'it-ch': 'dd.MM.yyyy',
    'it-it': 'dd/MM/yyyy',
    'iu': 'd/M/yyyy',
    'iu-cans-ca': 'd/M/yyyy',
    'iu-latn-ca': 'd/MM/yyyy',
    'ja': 'yyyy/MM/dd',
    'ja-jp': 'yyyy/MM/dd',
    'ka': 'dd.MM.yyyy',
    'ka-ge': 'dd.MM.yyyy',
    'kk': 'dd.MM.yyyy',
    'kk-kz': 'dd.MM.yyyy',
    'kl': 'dd-MM-yyyy',
    'kl-gl': 'dd-MM-yyyy',
    'km': 'yyyy-MM-dd',
    'km-kh': 'yyyy-MM-dd',
    'kn': 'dd-MM-yy',
    'kn-in': 'dd-MM-yy',
    'ko': 'yyyy-MM-dd',
    'ko-kr': 'yyyy-MM-dd',
    'kok': 'dd-MM-yyyy',
    'kok-in': 'dd-MM-yyyy',
    'ky': 'dd.MM.yy',
    'ky-kg': 'dd.MM.yy',
    'lb': 'dd/MM/yyyy',
    'lb-lu': 'dd/MM/yyyy',
    'lo': 'dd/MM/yyyy',
    'lo-la': 'dd/MM/yyyy',
    'lt': 'yyyy.MM.dd',
    'lt-lt': 'yyyy.MM.dd',
    'lv': 'yyyy.MM.dd.',
    'lv-lv': 'yyyy.MM.dd.',
    'mi': 'dd/MM/yyyy',
    'mi-nz': 'dd/MM/yyyy',
    'mk': 'dd.MM.yyyy',
    'mk-mk': 'dd.MM.yyyy',
    'ml': 'dd-MM-yy',
    'ml-in': 'dd-MM-yy',
    'mn': 'yy.MM.dd',
    'mn-mn': 'yy.MM.dd',
    'mn-mong-cn': 'yyyy/M/d',
    'moh': 'M/d/yyyy',
    'moh-ca': 'M/d/yyyy',
    'mr': 'dd-MM-yyyy',
    'mr-in': 'dd-MM-yyyy',
    'ms': 'dd/MM/yyyy',
    'ms-bn': 'dd/MM/yyyy',
    'ms-my': 'dd/MM/yyyy',
    'mt': 'dd/MM/yyyy',
    'mt-mt': 'dd/MM/yyyy',
    'nb': 'dd.MM.yyyy',
    'nb-no': 'dd.MM.yyyy',
    'ne': 'M/d/yyyy',
    'ne-np': 'M/d/yyyy',
    'nl': 'dd-MM-yyyy',
    'nl-be': 'dd-MM-yyyy',
    'nl-nl': 'dd-MM-yyyy',
    'nn': 'dd.MM.yyyy',
    'nn-no': 'dd.MM.yyyy',
    'nso': 'yyyy/MM/dd',
    'nso-za': 'yyyy/MM/dd',
    'oc': 'dd/MM/yyyy',
    'oc-fr': 'dd/MM/yyyy',
    'or': 'dd-MM-yy',
    'or-in': 'dd-MM-yy',
    'pa': 'dd-MM-yy',
    'pa-in': 'dd-MM-yy',
    'pl': 'yyyy-MM-dd',
    'pl-pl': 'yyyy-MM-dd',
    'prs': 'dd/MM/yy',
    'prs-af': 'dd/MM/yy',
    'ps': 'dd/MM/yy',
    'ps-af': 'dd/MM/yy',
    'pt': 'd/M/yyyy',
    'pt-br': 'd/M/yyyy',
    'pt-pt': 'dd-MM-yyyy',
    'qut': 'dd/MM/yyyy',
    'qut-gt': 'dd/MM/yyyy',
    'quz': 'dd/MM/yyyy',
    'quz-bo': 'dd/MM/yyyy',
    'quz-ec': 'dd/MM/yyyy',
    'quz-pe': 'dd/MM/yyyy',
    'rm': 'dd/MM/yyyy',
    'rm-ch': 'dd/MM/yyyy',
    'ro': 'dd.MM.yyyy',
    'ro-ro': 'dd.MM.yyyy',
    'ru': 'dd.MM.yyyy',
    'ru-ru': 'dd.MM.yyyy',
    'rw': 'M/d/yyyy',
    'rw-rw': 'M/d/yyyy',
    'sa': 'dd-MM-yyyy',
    'sa-in': 'dd-MM-yyyy',
    'sah': 'MM.dd.yyyy',
    'sah-ru': 'MM.dd.yyyy',
    'se': 'd.M.yyyy',
    'se-fi': 'd.M.yyyy',
    'se-no': 'dd.MM.yyyy',
    'se-se': 'yyyy-MM-dd',
    'si': 'yyyy-MM-dd',
    'si-lk': 'yyyy-MM-dd',
    'sk': 'd. M. yyyy',
    'sk-sk': 'd. M. yyyy',
    'sl': 'd.M.yyyy',
    'sl-si': 'd.M.yyyy',
    'sma': 'dd.MM.yyyy',
    'sma-no': 'dd.MM.yyyy',
    'sma-se': 'yyyy-MM-dd',
    'smj': 'dd.MM.yyyy',
    'smj-no': 'dd.MM.yyyy',
    'smj-se': 'yyyy-MM-dd',
    'smn': 'd.M.yyyy',
    'smn-fi': 'd.M.yyyy',
    'sms': 'd.M.yyyy',
    'sms-fi': 'd.M.yyyy',
    'sq': 'yyyy-MM-dd',
    'sq-al': 'yyyy-MM-dd',
    'sr': 'd.M.yyyy',
    'sr-cyrl-ba': 'd.M.yyyy',
    'sr-cyrl-cs': 'd.M.yyyy',
    'sr-cyrl-me': 'd.M.yyyy',
    'sr-cyrl-rs': 'd.M.yyyy',
    'sr-latn-ba': 'd.M.yyyy',
    'sr-latn-cs': 'd.M.yyyy',
    'sr-latn-me': 'd.M.yyyy',
    'sr-latn-rs': 'd.M.yyyy',
    'sv': 'yyyy-MM-dd',
    'sv-fi': 'd.M.yyyy',
    'sv-se': 'yyyy-MM-dd',
    'sw': 'M/d/yyyy',
    'sw-ke': 'M/d/yyyy',
    'syr': 'dd/MM/yyyy',
    'syr-sy': 'dd/MM/yyyy',
    'ta': 'dd-MM-yyyy',
    'ta-in': 'dd-MM-yyyy',
    'te': 'dd-MM-yy',
    'te-in': 'dd-MM-yy',
    'tg': 'dd.MM.yy',
    'tg-cyrl-tj': 'dd.MM.yy',
    'th': 'd/M/yyyy',
    'th-th': 'd/M/yyyy',
    'tk': 'dd.MM.yy',
    'tk-tm': 'dd.MM.yy',
    'tn': 'yyyy/MM/dd',
    'tn-za': 'yyyy/MM/dd',
    'tr': 'dd.MM.yyyy',
    'tr-tr': 'dd.MM.yyyy',
    'tt': 'dd.MM.yyyy',
    'tt-ru': 'dd.MM.yyyy',
    'tzm': 'dd-MM-yyyy',
    'tzm-lLatn-dz': 'dd-MM-yyyy',
    'ug': 'yyyy-M-d',
    'ug-cn': 'yyyy-M-d',
    'uk': 'dd.MM.yyyy',
    'uk-ua': 'dd.MM.yyyy',
    'ur': 'dd/MM/yyyy',
    'ur-pk': 'dd/MM/yyyy',
    'uz': 'dd.MM.yyyy',
    'uz-cyrl-uz': 'dd.MM.yyyy',
    'uz-latn-uz': 'dd/MM yyyy',
    'vi': 'dd/MM/yyyy',
    'vi-vn': 'dd/MM/yyyy',
    'wo': 'dd/MM/yyyy',
    'wo-sn': 'dd/MM/yyyy',
    'xh': 'yyyy/MM/dd',
    'xh-za': 'yyyy/MM/dd',
    'yo': 'd/M/yyyy',
    'yo-ng': 'd/M/yyyy',
    'zh': 'yyyy/M/d',
    'zh-cn': 'yyyy/M/d',
    'zh-cht': 'yyyy/M/d',
    'zh-hk': 'd/M/yyyy',
    'zh-mo': 'd/M/yyyy',
    'zh-sg': 'd/M/yyyy',
    'zh-tw': 'yyyy/M/d',
    'zu': 'yyyy/MM/dd',
    'zu-za': 'yyyy/MM/dd'
  }

  return lang ? formats[lang.toLowerCase()].toUpperCase() : 'DD/MM/YYYY'
}

const getLocaleTimeString = (lang) => {
  const timeFormat = {
    'af': 'HH:mm',
    'ar-dz': 'HH:mm',
    'ar-kw': 'HH:mm',
    'ar-ly': 'HH:mm',
    'ar-ma': 'HH:mm',
    'ar-sa': 'HH:mm',
    'ar-tn': 'HH:mm',
    'ar': 'HH:mm',
    'az': 'HH:mm',
    'be': 'HH:mm',
    'bg': 'H:mm',
    'bm': 'HH:mm',
    'bn-bd': 'A h:mm',
    'bn': 'A h:mm',
    'bo': 'A h:mm',
    'br': 'HH:mm',
    'bs': 'H:mm',
    'ca': 'H:mm',
    'cs': 'H:mm',
    'cv': 'HH:mm',
    'cy': 'HH:mm',
    'da': 'HH:mm',
    'de-at': 'HH:mm',
    'de-ch': 'HH:mm',
    'de': 'HH:mm',
    'dv': 'HH:mm',
    'el': 'h:mm A',
    'en': 'h:mm A',
    'en-us': 'h:mm A',
    'en-au': 'h:mm A',
    'en-ca': 'h:mm A',
    'en-gb': 'HH:mm',
    'en-ie': 'HH:mm',
    'en-il': 'HH:mm',
    'en-in': 'h:mm A',
    'en-nz': 'h:mm A',
    'en-sg': 'HH:mm',
    'eo': 'HH:mm',
    'es-do': 'h:mm A',
    'es-mx': 'H:mm',
    'es-us': 'h:mm A',
    'es': 'H:mm',
    'et': 'H:mm',
    'eu': 'HH:mm',
    'fa': 'HH:mm',
    'fi': 'HH.mm',
    'fil': 'h:mm A',
    'fo': 'HH:mm',
    'fr-ca': 'HH:mm',
    'fr-ch': 'HH:mm',
    'fr': 'HH:mm',
    'fy': 'HH:mm',
    'ga': 'HH:mm',
    'gd': 'HH:mm',
    'gl': 'H:mm',
    'gom-deva': 'A h:mm',
    'gom-latn': 'A h:mm',
    'gu': 'A h:mm',
    'he': 'HH:mm',
    'hi': 'A h:mm',
    'hr': 'H:mm',
    'hu': 'H:mm',
    'hy-am': 'HH:mm',
    'id': 'HH.mm',
    'is': 'H:mm',
    'it-ch': 'HH:mm',
    'it': 'HH:mm',
    'ja': 'HH:mm',
    'jv': 'HH.mm',
    'ka': 'HH:mm',
    'kk': 'HH:mm',
    'km': 'HH:mm',
    'kn': 'A h:mm',
    'ko': 'A h:mm',
    'ku': 'HH:mm',
    'ky': 'HH:mm',
    'lb': 'H:mm',
    'lo': 'HH:mm',
    'lt': 'HH:mm',
    'lv': 'HH:mm',
    'me': 'H:mm',
    'mi': 'HH:mm',
    'mk': 'H:mm',
    'ml': 'A h:mm',
    'mn': 'HH:mm',
    'mr': 'A h:mm',
    'ms-my': 'HH.mm',
    'ms': 'HH.mm',
    'mt': 'HH:mm',
    'my': 'HH:mm',
    'nb': 'HH:mm',
    'ne': 'h:mm',
    'nl-be': 'HH:mm',
    'nl': 'HH:mm',
    'nn': 'HH:mm',
    'oc-lnc': 'H:mm',
    'pa-in': 'A h:mm',
    'pl': 'HH:mm',
    'pt-br': 'HH:mm',
    'pt': 'HH:mm',
    'ro': 'H:mm',
    'ru': 'H:mm',
    'sd': 'HH:mm',
    'se': 'HH:mm',
    'si': 'a h:mm',
    'sk': 'H:mm',
    'sl': 'H:mm',
    'sq': 'HH:mm',
    'sr-cyrl': 'H:mm',
    'sr': 'H:mm',
    'ss': 'h:mm A',
    'sv': 'HH:mm',
    'sw': 'hh:mm A',
    'ta': 'HH:mm',
    'te': 'A h:mm',
    'tet': 'HH:mm',
    'tg': 'HH:mm',
    'th': 'H:mm',
    'tk': 'HH:mm',
    'tl-ph': 'HH:mm',
    'tlh': 'HH:mm',
    'tr': 'HH:mm',
    'tzl': 'HH.mm',
    'tzm-latn': 'HH:mm',
    'tzm': 'HH:mm',
    'ug-cn': 'HH:mm',
    'uk': 'HH:mm',
    'ur': 'HH:mm',
    'uz-latn': 'HH:mm',
    'uz': 'HH:mm',
    'vi': 'HH:mm',
    'x-pseudo': 'HH:mm',
    'yo': 'h:mm A',
    'zh-cn': 'HH:mm',
    'zh-hk': 'HH:mm',
    'zh-mo': 'HH:mm',
    'zh-tw': 'HH:mm',
  }

  return timeFormat[lang.toLowerCase()] || 'HH:mm'
}


export { getLocaleDateString, getLocaleTimeString }
