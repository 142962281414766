import Layout from '../components/layout'
import './Category.scss'
import React, { Component } from 'react'
import { getVariableValue } from '$ustoreinternal/services/cssVariables'
import { InputGroup } from 'reactstrap'
import urlGenerator from '$ustoreinternal/services/urlGenerator'
import { t } from '$themelocalization'
import {Link, useNavigate} from 'react-router-dom'
import themeContext from "$ustoreinternal/services/themeContext"
import formSend from '../xmpie-skins-services/form-send/formSend'
import ReactHtmlParser from 'react-html-parser'

class Contacts extends Component {

  constructor (props) {
    super(props)
    const { storeFriendlyID } = themeContext.get();

    this.state = {
      isMobile: false,
      formData: {
        storeID: storeFriendlyID,
        name: '',
        email: '',
        phone: '',
        message: ''
      },
      formError: false,
      urlContacts: ''
    }
  }

  componentDidMount () {
    const postContactsUrl = getVariableValue('--skin1-postContactsUrl', '')
    this.setState({
      urlContacts: postContactsUrl
    })
  }

  handleFileChange = e => {
    const formData = this.state.formData
    formData[e.target.name] = e.target.value
    this.setState({
      formData: formData, 
      formError: false
    })
  }

  handleSubmit = async e => {
    e.preventDefault()
    
    const postContactsUrl = getVariableValue('--skin1-postContactsUrl', false)

    if( formSend(this.state.formData, postContactsUrl) && this.state.formData.name && this.state.formData.message ) {
      this.props.navigate(urlGenerator.get({ page: 'ThankYouPage' }))
    } else { 
      this.setState({
          formError: true
      })
    }
  }

  render () {
    const contactsImage = getVariableValue('--skin1-contactsImage', require(`$assets/images/map.jpg`), true)
    const shortInfo = getVariableValue('--skin1-postContactsText', '')
    const shortInfoTitle = getVariableValue('--skin1-postContactsTitle', '')
    const contactNumber = getVariableValue('--skin1-homepage-contactPage-number', '')
    const contactNumber2 = getVariableValue('--skin1-homepage-contactPage-number2', '')
    const workDays = getVariableValue('--skin1-contacts-workdays', '')
    const workSat = getVariableValue('--skin1-contacts-saturday', '')
    const workSun = getVariableValue('--skin1-contacts-sunday', '')
    const workDaysTitle = getVariableValue('--skin1-contacts-workdays-title', '')
    const workSatTitle = getVariableValue('--skin1-contacts-saturday-title', '')
    const workSunTitle = getVariableValue('--skin1-contacts-sunday-title', '')
    const address = getVariableValue('--skin1-contacts-adress', '')
    const postContactsUrl = getVariableValue('--skin1-postContactsUrl', '')
    const contactInfo1 = getVariableValue('--skin1-contactInfo1', '')
    const contactInfo2 = getVariableValue('--skin1-contactInfo2', '')
    const email = getVariableValue('--skin1-email', t('Footer.payment'))
    const adressTitle = getVariableValue('--skin1-contacts-adress-title', '')
    const phoneTitle = getVariableValue('--skin1-contacts-phone-title', '')
    const emailTitle = getVariableValue('--skin1-contacts-email-title', '')

    return (
      <Layout {...this.props}>
        <div className='container'>
          <div className="breadcrumbs">
              <Link to={urlGenerator.get({ page: 'home' })}>
              <a>{t('xmp_theme_collage_home')}</a>
              </Link>
              <span>></span>
              <a>{t('xmp_theme_contact_us')}</a>
          </div>
          <span className="title-page">{t('xmp_theme_contact_us')}</span>
          <div className='contacts-wrap'>
            <img src={`${contactsImage}`} alt=""/>
            <div className='send-form'>
              {this.state.urlContacts.length === 0
                ? null :
                <div className='drop-us-line'>
                  <span>{shortInfoTitle}</span>
                  <div>
                    {ReactHtmlParser(shortInfo)}
                  </div>
                  <form onSubmit={this.handleSubmit}>
                    <div className='forms'>
                      <div className="row">
                        <div className='contact-form-group col-lg-6'>
                          <span>{t('xmp_theme_name')}<span>*</span></span>
                          <InputGroup size="small">
                            <input className="footer-search-input form-control"
                                   placeholder={t('xmp_theme_name_placeholder')} type="text" name="name"
                                   onChange={this.handleFileChange}/>
                          </InputGroup>
                        </div>
                        <div className='contact-form-group col-lg-6'>
                          <span>{t('xmp_theme_email')}<span>*</span></span>
                          <InputGroup size="small">
                            <input className="footer-search-input form-control"
                                   placeholder={t('xmp_theme_enter_your_email')} type="email" name="email"
                                   onChange={this.handleFileChange}/>
                          </InputGroup>
                        </div>
                        <div className='contact-form-group col-lg-6'>
                          <span>{t('xmp_theme_phone')}</span>
                          <InputGroup size="small">
                            <input className="footer-search-input form-control"
                                   placeholder={t('xmp_theme_phone_placeholder')} type="text" name="phone"
                                   onChange={this.handleFileChange}/>
                          </InputGroup>
                        </div>
                        <div className='contact-form-group col-lg-6'>
                          <span>{t('xmp_theme_message')}<span>*</span></span>
                          <InputGroup size="small">
                            <input className="footer-search-input form-control"
                                   placeholder={t('xmp_theme_message_placeholder')} type="text" name="message"
                                   onChange={this.handleFileChange}/>
                          </InputGroup>
                        </div>
                      </div>
                      {this.state.formError ? <span className='error-message'>{t('xmp_theme_err')}</span>
                        : <></>
                      }
                    </div>
                    <button type="submit" className='contacts-subscription-button'>
                      {t('xmp_theme_submit')}
                    </button>
                  </form>
                </div>
              }
            </div>
            <div className='contact-items'>
              <div className='contact-item'>
                <span>{ReactHtmlParser(contactInfo1)}</span>
                <div>{ReactHtmlParser(contactInfo2)}</div>
              </div>
              <div className='contact-item'>
                <span>{t('xmp_theme_contact_us')}</span>
                <div className='contact-item-inner'>
                  <div className='inner-child'>
                    {address.length === 0
                      ? <a className='empty-div'/>
                      : <div><span>{adressTitle}</span>:&nbsp;<span>{address}</span></div>
                    }</div>
                  <div className='inner-child'>
                    {contactNumber.length === 0 && contactNumber2.length === 0
                      ? <a className='empty-div'/>
                      : <div>{phoneTitle}:&nbsp;</div>
                    }
                    <span>
                      {contactNumber.length === 0
                        ? <a className='empty-div'/>
                        : <div>{contactNumber}&nbsp;&nbsp;</div>
                      }
                      {contactNumber2.length === 0
                        ? <a className='empty-div'/>
                        : <div>{contactNumber2}</div>
                      }
                      </span>
                  </div>
                  <div className='inner-child'><a>{email.length === 0
                    ? <a className='empty-div'/>
                    : <div>{emailTitle}: <span>{email}</span></div>}
                  </a></div>
                  <div className=''>

                    <span>{workDaysTitle}&nbsp;</span>
                    <span>
                      <span>{workDays}</span>&nbsp;&nbsp;
                    </span>
                    <br/>
                    <span>{workSatTitle}&nbsp;</span>
                    <span>
                      <span>{workSat}</span>&nbsp;&nbsp;
                    </span>
                    <br/>
                    <span>{workSunTitle}&nbsp;</span>
                    <span>
                      <span>{workSun}</span>&nbsp;&nbsp;
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

function addHookTo(Component) {
  function CompWithHook(props) {
    const navigate = useNavigate();

    return <Component {...props} navigate={navigate} />;
  }

  return CompWithHook;
}

export default addHookTo(Contacts)