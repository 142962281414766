import './Footer.scss';
import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import urlGenerator from '$ustoreinternal/services/urlGenerator';
import { getVariableValue } from '$ustoreinternal/services/cssVariables';
import { decodeStringForURL } from '$ustoreinternal/services/utils'
import themeContext from "$ustoreinternal/services/themeContext";
import formSend from '../../xmpie-skins-services/form-send/formSend';
import ReactHtmlParser from 'react-html-parser';
import Slot from '../widgets/Slot';
import { t } from '$themelocalization';
import Icon from '$core-components/Icon';

const Footer = ({ categories }) => {
    const navigate = useNavigate()
    const { storeFriendlyID } = themeContext.get();
    const [isMobile, setIsMobile] = useState(false);
    const [linkFacebook, setLinkFacebook] = useState('');
    const [linkLinkedIn, setLinkLinkedIn] = useState('');
    const [linkTwitter, setLinkTwitter] = useState('');
    const [linkInst, setLinkInst] = useState('');
    const [formData, setFormData] = useState({ storeID: storeFriendlyID, email: '' });
    const [formError, setFormError] = useState(false);
    const [urlNewsletter, setUrlNewsletter] = useState('');

    const handleFileChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setFormError(false);
    };

    const scrollTop = () => {
        window.scrollTo(0,0)
    };

    const handleSubmit = async e => {
        e.preventDefault();
        const postContactsUrl = getVariableValue('--skin1-postUrlNewsLetter', false);

        if (formSend(formData, postContactsUrl)) {
            navigate(urlGenerator.get({ page: 'ThankYouPage' }));
        } else {
            setFormError(true);
        }
    };

    useEffect(() => {
        const postUrlNewsLetter = getVariableValue('--skin1-postUrlNewsLetter', false);
        setUrlNewsletter(postUrlNewsLetter);
    }, []);

    useEffect(() => {
        setLinkFacebook(getVariableValue('--skin1-homepage-footer-linkFacebook', ''));
        setLinkLinkedIn(getVariableValue('--skin1-homepage-footer-linkLinkedIn', ''));
        setLinkTwitter(getVariableValue('--skin1-homepage-footer-linkTwitter', ''));
        setLinkInst(getVariableValue('--skin1-homepage-footer-linkInst', ''));
    }, []);

    const footerCustomInfo = getVariableValue('--skin1-homepage-footerCustomInfo', '')
    const workDays = getVariableValue('--skin1-contacts-workdays', '')
    const workSat = getVariableValue('--skin1-contacts-saturday', '')
    const workSun = getVariableValue('--skin1-contacts-sunday', '')
    const workDaysTitle = getVariableValue('--skin1-contacts-workdays-title', '')
    const workSatTitle = getVariableValue('--skin1-contacts-saturday-title', '')
    const workSunTitle = getVariableValue('--skin1-contacts-sunday-title', '')
    const copyright = getVariableValue('--skin1-homepage-footer-copyright', '')
    const contactNumber = getVariableValue('--skin1-homepage-contactPage-number')
    const contactNumber2 = getVariableValue('--skin1-homepage-contactPage-number2')
    const page1 = getVariableValue('--skin1-page1', '')
    const page1Link = getVariableValue('--skin1-page1Link', '')
    const page2 = getVariableValue('--skin1-page2', '')
    const page2Link = getVariableValue('--skin1-page2Link', '')
    const page3 = getVariableValue('--skin1-page3', '')
    const page3Link = getVariableValue('--skin1-page3Link', '')
    const page4 = getVariableValue('--skin1-page4', '')
    const page4Link = getVariableValue('--skin1-page4Link', '')
    const page5 = getVariableValue('--skin1-page5', '')
    const page5Link = getVariableValue('--skin1-page5Link', '')
    const address = getVariableValue('--skin1-contacts-adress', '')
    const email = getVariableValue('--skin1-email')
    const shipping1 = getVariableValue('--skin1-shipping1', '')
    const shipping2 = getVariableValue('--skin1-shipping2', '')
    const moneyGuarantee1 = getVariableValue('--skin1-moneyGuarantee1', '')
    const moneyGuarantee2 = getVariableValue('--skin1-moneyGuarantee2', '')
    const onlineSupport1 = getVariableValue('--skin1-onlineSupport1', '')
    const onlineSupport2 = getVariableValue('--skin1-onlineSupport2', '')
    const secureSupport1 = getVariableValue('--skin1-secureSupport1', '')
    const secureSupport2 = getVariableValue('--skin1-secureSupport2', '')
    const postUrlNewsLetter = getVariableValue('--skin1-postUrlNewsLetter', '')
    const column1Title = getVariableValue('--skin1-footer-column-1', '')
    const column2Title = getVariableValue('--skin1-footer-column-2', '')
    const column3Title = getVariableValue('--skin1-footer-column-3', '')
    const column4Title = getVariableValue('--skin1-footer-column-4', '')
    const adressTitle = getVariableValue('--skin1-contacts-adress-title', '')
    const phoneTitle = getVariableValue('--skin1-contacts-phone-title', '')
    const emailTitle = getVariableValue('--skin1-contacts-email-title', '')

    return (
        <div>
            <div className="over-top">
                <div className="container">
                    <div className='row'>
                        {shipping1 === 0 ? <></>
                            : <div className="col-lg-3 col-md-6">
                                <div className='money-guarantee'>
                                    <Icon name="local_shipping-24px.svg" width="35px" height="35px"/>
                                    <div className='return'>
                                        <span>{shipping1}</span>
                                        <span>{shipping2}</span>
                                    </div>
                                </div>
                            </div>
                        }
                        {moneyGuarantee1 === 0 ? <></>
                            : <div className="col-lg-3 col-md-6">
                                <div className='money-guarantee'>
                                    <Icon name="money-24px.svg" width="35px" height="35px"/>
                                    <div className='return'>
                                        <span>{moneyGuarantee1}</span>
                                        <span>{moneyGuarantee2}</span>
                                    </div>
                                </div>
                            </div>
                        }
                        {onlineSupport1 === 0 ? <></>
                            : <div className="col-lg-3 col-md-6">
                                <div className='money-guarantee'>
                                    <Icon name="call-24px.svg" width="35px" height="35px"/>
                                    <div className='return'>
                                        <span>{onlineSupport1}</span>
                                        <span>{onlineSupport2}</span>
                                    </div>
                                </div>
                            </div>
                        }
                        {secureSupport1 === 0 ? <></>
                            : <div className="col-lg-3 col-md-6">
                                <div className='money-guarantee'>
                                    <Icon name="lock-24px.svg" width="35px" height="35px"/>
                                    <div className='return'>
                                        <span>{secureSupport1}</span>
                                        <span>{secureSupport2}</span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className='footer'>
                <Slot name="footer" />
                <div className="container">
                    <div className="top">

                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="title-footer">{column1Title}</div>
                                {categories && categories.length > 0 && categories.map((index) => {
                                    if (index) {
                                        return (
                                            <Link key={index.FriendlyID} to={urlGenerator.get({
                                                page: 'category',
                                                id: index.FriendlyID,
                                                name: decodeStringForURL(index.Name)
                                            })}>
                                        <span
                                            onClick={scrollTop}
                                            className={`category-footer-title`}
                                            key={index.FriendlyID} id={`id-${index.FriendlyID}`}>
                                        <a className="link" key={index.FriendlyID}>{index.Name}</a>
                                        </span>

                                            </Link>
                                        )
                                    }
                                })}
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="title-footer">{column2Title}</div>
                                {page1Link.length === 0
                                    ? <a className='empty-div'/>
                                    : <span className="category-footer-title" onClick={
                                        () => {
                                            window.open(page1Link)
                                        }}>
                      {page1}
                      </span>
                                }
                                {page2Link.length === 0
                                    ? <a className='empty-div'/>
                                    : <span className="category-footer-title" onClick={
                                        () => {
                                            window.open(page2Link)
                                        }}>
                      {page2}
                      </span>
                                }
                                {page3Link.length === 0
                                    ? <a className='empty-div'/>
                                    : <span className="category-footer-title" onClick={
                                        () => {
                                            window.open(page3Link)
                                        }}>
                      {page3}
                      </span>
                                }
                                {page4Link.length === 0
                                    ? <a className='empty-div'/>
                                    : <span className="category-footer-title" onClick={
                                        () => {
                                            window.open(page4Link)
                                        }}>
                      {page4}
                      </span>
                                }
                                {
                                    page5Link.length === 0
                                        ? <a className='empty-div'/>
                                        : <span className="category-footer-title" onClick={
                                            () => {
                                                window.open(page5Link)
                                            }}>
                      {page5}
                      </span>
                                }
                                <span onClick={scrollTop} className="category-footer-title">
                      {<Link key={0} to={urlGenerator.get({ page: 'about-us' })}>
                          <a key={0}>{t('xmp_theme_about_us')}</a>
                      </Link>}
                    </span>
                                <span onClick={scrollTop}  className="category-footer-title">
                      {<Link key={1} to={urlGenerator.get({ page: 'contacts' })}>
                          <a key={0}>{t('xmp_theme_contact_us')}</a>
                      </Link>}
                    </span>

                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="title-footer">{column3Title}</div>
                                {address ?
                                    address.length === 0
                                        ? <a className='empty-div'/>
                                        : <div className="line"><span className="name-line">{adressTitle}: </span><span
                                            className="value-line">{address}</span></div>
                                    : <a className='empty-div'/>}

                                {contactNumber ?
                                    contactNumber.length === 0
                                        ? <a className='empty-div'/>
                                        : <div className="line"><span className="name-line">{phoneTitle}: </span><span
                                            className="value-line">{contactNumber},&nbsp;{contactNumber2}</span></div> : <a
                                        className='empty-div'/>
                                }
                                {email ?
                                    email.length === 0
                                        ? <a className='empty-div'/>
                                        : <div className="line"><span className="name-line">{emailTitle}: </span><span
                                            className="value-line">{email}</span></div>
                                    : <a className='empty-div'/>
                                }
                                <div className="line">
                                    <span className="name-line">{workDaysTitle}: </span>
                                    <span className="value-line">{workDays}</span>&nbsp;&nbsp;
                                    <span className="value-line">{workSat}</span>&nbsp;&nbsp;
                                    <span className="value-line">{workSun}</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="title-footer">{column4Title}</div>
                                <span className="info-footer">{ReactHtmlParser(footerCustomInfo)}</span>
                                {postUrlNewsLetter.length === 0
                                    ? <></>
                                    : <div className="footer-search">
                                        <form onSubmit={handleSubmit}>
                                            <div className='subscription-wrap'>
                                                <input className='subscription-input' type="email" name="email"
                                                       onChange={handleFileChange}
                                                       placeholder={t('xmp_theme_enter_your_email')}/>
                                                <button className='subscription-button' type="submit">{ t('xmp_theme_collage_get') }</button>
                                            </div>
                                            {formError ? <span className='error-message'>{t('xmp_theme_err')}</span>
                                                : <></>
                                            }
                                        </form>
                                    </div>}
                                <div className='social-networks'>
                                    {linkFacebook.length === 0
                                        ? <a className='empty-div'/>
                                        : <div onClick={
                                            () => {
                                                window.open(linkFacebook)
                                            }}>
                                            <Icon name="btn_facebook.svg" width="30px" height="30px"/>
                                        </div>
                                    }
                                    {linkLinkedIn.length === 0 ? <a className='empty-div'/> : <div onClick={
                                        () => {
                                            window.open(linkLinkedIn)
                                        }}
                                    >
                                        <Icon name="btn_linked.svg" width="30px" height="30px"/>
                                    </div>
                                    }
                                    {linkTwitter.length === 0 ? <a className='empty-div'/> : <div onClick={
                                        () => {
                                            window.open(linkTwitter)
                                        }}
                                    >
                                        <Icon name="btn_twitter.svg" width="22px" height="22px"/>
                                    </div>
                                    }
                                    {linkInst.length === 0 ? <a className='empty-div'/> : <div onClick={
                                        () => {
                                            window.open(linkInst)
                                        }}
                                    >
                                        <Icon name="btn_inst.svg" width="22px" height="22px"/>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='bottom'>
                    <div className="container">
                        <span>{copyright}</span>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Footer;
