import { initStore } from '../redux/store'
import { CLEAR_STATE, SET_CUSTOM_VAR, DELETE_CUSTOM_VAR, MERGE_CUSTOM_STATE } from '../redux/actionsTypes'

class ProviderState {
  constructor () {
    this.store = initStore()
  }

  get () {
    return this.store.getState()
  }

  set (state) {
    this.store.dispatch({ type: '@@INIT_STATE', data: state })
  }

  setInitState () {
    this.store.dispatch({ type: '@@INIT_STATE' })
  }

  get dispatch () {
    return this.store.dispatch
  }

  get subscribe () {
    return this.store.subscribe
  }

  setCustomState (name, value) {
    this.store.dispatch({ type: SET_CUSTOM_VAR, data: { [name]: value } })
  }

  setBulkCustomState (obj) {
    this.store.dispatch({ type: SET_CUSTOM_VAR, data: obj })
  }

  deleteCustomState (name) {
    this.store.dispatch({ type: DELETE_CUSTOM_VAR, data: { name } })
  }

  mergeCustomState (newState) {
    this.store.dispatch({ type: MERGE_CUSTOM_STATE, data: newState })
  }

  clear () {
    this.store.dispatch({ type: CLEAR_STATE, data: {} })
  }
}

export default new ProviderState()
