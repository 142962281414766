/**
 * @function Profile - Dropdown for profile settings and actions
 *
 * @param {object} currentUser - should contains at least FirstName
 * @param {object} [userOrdersSummary] - data regarding the rejected/pending orders of the user in an approval process of the store
 */

import { Component } from 'react'
import {useNavigate} from 'react-router-dom'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import SignIn from './SignIn'
import SignOut from './SignOut'
import { Icon } from '$core-components'
import { CookiesManager, storefrontCookies } from '$ustoreinternal/services/cookies'
import { redirectToLegacy } from '$ustoreinternal/services/redirect'
import themeContext from '$ustoreinternal/services/themeContext'
import urlGenerator from '$ustoreinternal/services/urlGenerator'
import { UStoreProvider } from '@ustore/core'
import { t } from '$themelocalization'
import './Profile.scss'
import { CART_MODE, getCartMode } from '../../../services/utils'

const createLink = (anonymous, loginURL, pageTitle, additional) => {
  const { languageCode } = themeContext.get()
  const pageURL = urlGenerator.get({ page: pageTitle })

  if (anonymous) {
    return `${loginURL}&returnNGURL=/${encodeURIComponent(pageURL.slice(pageURL.indexOf(languageCode)))}${additional ? '?' + additional : ''}`
  }

  return `${pageURL}${additional ? '?' + additional : ''}`
}

const LinkComponent = ({ pageName, IsAnonymous, loginPage, pageTitle, additional }) => {
  const navigate = useNavigate();
  return IsAnonymous
    ? (
        <DropdownItem tag="a" onClick={() => {
          CookiesManager.deleteCookie(storefrontCookies.token)
          redirectToLegacy(createLink(IsAnonymous, loginPage, pageTitle, additional))
        }}>
          {pageName}
        </DropdownItem>
      ) : (
        <DropdownItem tag="a" onClick={() => {
          navigate(createLink(IsAnonymous, loginPage, pageTitle, additional))
        }}>
          {pageName}
        </DropdownItem>
      )
}

class Profile extends Component {
  constructor (props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      isDropdownOpen: false
    }
  }

  toggle () {
    this.setState(prevState => ({
      isDropdownOpen: !prevState.isDropdownOpen
    }))
  }

  render () {
    const { currentUser, userOrdersSummary } = this.props
    const { userID, storeID, securityToken, storeFriendlyID, languageCode } = themeContext.get()

    const userIDFromStore = UStoreProvider.state.get().currentUser.ID
    const tempUserId = (!userID || (userIDFromStore && userIDFromStore !== userID)) ? userIDFromStore : userID
    const isUStoreConnectEnterprise =
      UStoreProvider.state.get().currentStore.Attributes.find(attr => attr.Name === 'HomepageToCart')?.Value === 'True'
    const isSingleList = getCartMode(UStoreProvider.state.get().currentStore) === CART_MODE.SingleList

    if (!currentUser) {
      return null
    }

    const rejectedOrderCount = (userOrdersSummary) ? userOrdersSummary.RejectedOrderCount : null
    const pendingApprovalOrderCount = (userOrdersSummary) ? userOrdersSummary.PendingApprovalOrderCount : null
    const loginPage = `/logout.aspx?tempUserId=${tempUserId}&SecurityToken=${securityToken}&StoreGuid=${storeID}&storeid=${storeFriendlyID}&NgLanguageCode=${languageCode}&forceLogin=true&ShowRibbon=false&SignIn=true`
    const IsAnonymous = currentUser.IsAnonymous

    return (
      <Dropdown
        isOpen={this.state.isDropdownOpen}
        toggle={this.toggle}
        direction="start"
        className="profile"
      >
        <DropdownToggle
          tag='div'
          data-toggle='dropdown'
        >
          {/* <i className="fas fa-user fa-lg"></i> */}
          <div className="profile-icon-container">
            <Icon name="person-24px.svg" width="20px" height="20px" className="profile-icon" />
            {
              pendingApprovalOrderCount > 0 &&
              <div className="notification-icon">
                <Icon name="profile-notification.svg" width="20px" height="20px" className="profile-icon" />
              </div>
            }
          </div>
        </DropdownToggle>
        <DropdownMenu>
          {
            IsAnonymous
              ? <SignIn />
              : <SignOut currentUser={currentUser} />
          }
          <div className="dd-body">
            {!isSingleList && <LinkComponent
                  pageName={t('Profile.My_orders')}
                  IsAnonymous={IsAnonymous}
                  loginPage={loginPage}
                  pageTitle="order-history"
                  additional="filter=0"
              />}

            {userOrdersSummary && currentUser.Roles.Shopper &&
                (<LinkComponent
                    pageName={t('Profile.Rejected_orders', {rejectedOrderCount})}
                    IsAnonymous={IsAnonymous}
                    loginPage={loginPage}
                    pageTitle="order-history"
                    additional="filter=2"
                />)
            }
            {userOrdersSummary && currentUser.Roles.Approver &&
                (<LinkComponent
                    pageName={t('Profile.Orders_to_approve', {pendingApprovalOrderCount})}
                    IsAnonymous={IsAnonymous}
                    loginPage={loginPage}
                    pageTitle="order-approval-list"
                />)
            }
            <LinkComponent
              pageName={t('Profile.Draft_orders')}
              IsAnonymous={IsAnonymous}
              loginPage={loginPage}
              pageTitle="drafts"
            />
              <LinkComponent
                  pageName={t('Profile.Recipient_lists')}
                  IsAnonymous={IsAnonymous}
                  loginPage={loginPage}
                  pageTitle="my-recipient-lists"
              />
            {!isUStoreConnectEnterprise && <>
              <LinkComponent
                pageName={t('Profile.Addresses')}
                IsAnonymous={IsAnonymous}
                loginPage={loginPage}
                pageTitle="addresses"
              />
              <LinkComponent
                pageName={t('Profile.Personal_information')}
                IsAnonymous={IsAnonymous}
                loginPage={loginPage}
                pageTitle="personal-information"
              />
            </>}
          </div>
        </DropdownMenu>
      </Dropdown>
    )
  }
}

export default Profile
