import Layout from '../components/layout'
import './Category.scss'
import React, { Component } from 'react'
import { getVariableValue } from '$ustoreinternal/services/cssVariables'
import urlGenerator from '$ustoreinternal/services/urlGenerator'
import { t } from '$themelocalization'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

class AboutUs extends Component {

  constructor (props) {
    super(props)

    this.state = {
      isMobile: false
    }
  }

  render () {
    const aboutUsImage = getVariableValue('--skin1-about-us-image', require(`$assets/images/Lamb-and-Bluebird.jpg`), true)
    const aboutUsInfo = getVariableValue('--skin1-homepage-categories-aboutUsInfo', '')
    const aboutUsInfo2 = getVariableValue('--skin1-homepage-categories-aboutUsInfo2', '')
      console.log(aboutUsInfo,'aboutUsInfo')
    return (
      <Layout {...this.props}>
        <div className='container'>
          <div className="breadcrumbs">
              <Link to={urlGenerator.get({ page: 'home' })}>
              <a>{t('xmp_theme_collage_home')}</a>
              </Link>
              <span>></span>
              <a>{t('xmp_theme_about_us')}</a>
          </div>
          <span className="title-page">{t('xmp_theme_about_us')}</span>
          <div className='about-father-wrap'>
            <img src={`${aboutUsImage}`} className='aboutUsImage' alt="aboutUsImage"/>
            <div className='about-info-container'>
              <div className='about-us-wrap'>{ReactHtmlParser(aboutUsInfo)}</div>
              <div className='about-us-wrap'>{ReactHtmlParser(aboutUsInfo2)}</div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AboutUs
