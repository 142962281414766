import './CustomCategoryItem.scss'
import { useNavigate} from 'react-router-dom'
import LinesEllipsis from 'react-lines-ellipsis'
import React from 'react'

/**
 * This component represents the category in the store
 * It contains the category image and details
 *
 * @param {object} model - the category
 * @param {object} subCategories - the category
 * @param {boolean} isBottom - isBottom
 * @param {string} url - the url to redirects when clicking the category
 * @param {string} [className] - the css class to add to main div
 */
const CustomCategoryItem = ({ model, subCategories, url, className, isBottom }) => {
  const navigate = useNavigate()

  const goTo = () => {
    navigate(url)
  }

  if (!model) {
    return <div className="category-item"/>
  }

  const imageUrl = model.ImageUrl ? model.ImageUrl : require(`$assets/images/default.png`)
  const style = {
    background: '#F8FAFD url(' + (imageUrl) + ') center / auto 100% no-repeat',
    width: isBottom ? '350px' : '100%',
    height: isBottom ? '320px' : 270,
  }
  let width = '330px'
  /*if (window.innerWidth < 380) {
    width = '300px'
  }*/
  if (subCategories && subCategories.length === 2) {
    width = '93%'
  }
  if (subCategories && subCategories.length === 3) {
    width = '89.2%'
  }
  if (subCategories && subCategories.length === 4) {
    width = '85.2%'
  }
  if (subCategories && subCategories.length >= 5) {
    width = '89.2%'
  }

  return (
      <a onClick={()=>goTo(url)} className={`category-item ${className || ''}`}>
        <div style={style} className="image-wrapper">
          <div className='subcategory-inner' style={{ width: width }}>
            <div className="category-name">
              <LinesEllipsis style={{ whiteSpace: 'pre-wrap' }} text={model.Name} maxLine={2} basedOn='words'/>
            </div>
            <div className="category-desc">
              <LinesEllipsis style={{ whiteSpace: 'pre-wrap' }} text={model.Description} maxLine={2} basedOn='words'/>
            </div>
          </div>
        </div>
      </a>
  )
}


export default CustomCategoryItem