import React, { useEffect } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import './ProductThumbnailsPreview.scss'
import DynamicImageViewer from './DynamicImageViewer'
import ErrorBalloon from '../ErrorBalloon'
import { t } from '$themelocalization'

const ProofErrorBalloon = ({ show }) => show &&
  <ErrorBalloon
    className="mobile-preview-failure">{t('productProof.PreviewFailure')}</ErrorBalloon>

const ProductThumbnailsPreview = ({
  productThumbnails,
  isModalOpen,
  onCloseModal,
  modalClassName,
  onImageChange,
  poofPreviewError,
  onProofPreviewClick,
}) => {

  useEffect(() => {
    if (isModalOpen)
    onProofPreviewClick()
  }, [isModalOpen])

  return (
    <Modal toggle={onCloseModal} isOpen={isModalOpen} modalClassName={`${modalClassName}`}>
      <div className="modal-close">
        <div className="close-btn" onClick={onCloseModal}>×</div>
      </div>
      <ModalBody>
        <div className="thumbnail-modal-image mobile">
          <ProofErrorBalloon show={poofPreviewError}/>
          <DynamicImageViewer
            thumbnails={productThumbnails.Thumbnails}
            onChange={(id) => onImageChange(id)}
          />
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ProductThumbnailsPreview
