import providerState from './providerState'
import { LOAD_CATEGORIES_TREE, LOAD_SUB_CATEGORIES, LOAD_CURRENT_CATEGORY } from '../redux/actionsTypes'
import {
  getCategoryIDByFriendlyID,
  getCategoryTree,
  getSubCategories,
  getCategory
} from '../api/categories'

/**
 * Represent the categories logic and API in the system
 */
class Categories {
  /**
   * Set **state.categoriesTree** with the categories tree.
   * Set **state.categories** with the first level categories group.
   * @param {number} [depth] - Indicates the maximum depth of the tree to return. By default (1), returns root categories only.
   * @returns {CategoryTreeModel} - The tree of categories
   */
  async loadCategoriesTree (depth = 1) {
    const { Categories } = await getCategoryTree(depth)
    providerState.dispatch({ type: LOAD_CATEGORIES_TREE, data: Categories })
    return Categories
  }

  /**
   * Set **state.subCategories** with the sub-categories of the given category in the store, using paging.
   * Sub-categories without online product(s) on any level of their branch - are not included.
   * @param {string} categoryFriendlyID - the category friendly ID
   * @param {number} [pageNumber] - The 1-based number of the page.
   * @param {number} [pageSize] - The amount of the categories in one page.
   * @returns Array of sub categories in a specific page
   */
  async loadSubCategories (categoryFriendlyID, pageNumber = null, pageSize = null) {
    const categoryID = await getCategoryIDByFriendlyID(categoryFriendlyID)
    const { Categories } = await getSubCategories(categoryID, pageNumber, pageSize)
    providerState.dispatch({ type: LOAD_SUB_CATEGORIES, data: Categories })
    return Categories
  }

  /**
   * Set state.currentCategory with the requested category by friendlyID.
   * @param {string} categoryFriendlyID - the category friendly ID
   * @returns {CategoryModel} - A category model
   */
  async loadCurrentCategory (categoryFriendlyID) {
    const categoryID = await getCategoryIDByFriendlyID(categoryFriendlyID)
    const currentCategory = await getCategory(categoryID)
    providerState.dispatch({ type: LOAD_CURRENT_CATEGORY, data: currentCategory })
    return currentCategory
  }
}

export default new Categories()
