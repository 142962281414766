export const LOAD_CURRENT_STORE = 'LOAD_CURRENT_STORE'
export const LOAD_CURRENT_USER = 'LOAD_CURRENT_USER'
export const LOAD_USER_ORDERS_SUMMARY = 'LOAD_USER_ORDERS_SUMMARY'
export const LOAD_PRODUCTS = 'LOAD_PRODUCTS'
export const SET_CUSTOM_VAR = 'SET_CUSTOM_VAR'
export const DELETE_CUSTOM_VAR = 'DELETE_CUSTOM_VAR'
export const MERGE_CUSTOM_STATE = 'MERGE_CUSTOM_STATE'
export const LOAD_CATEGORIES = 'LOAD_CATEGORIES'
export const LOAD_CATEGORIES_TREE = 'LOAD_CATEGORIES_TREE'
export const LOAD_SUB_CATEGORIES = 'LOAD_SUB_CATEGORIES'
export const LOAD_CULTURES = 'LOAD_CULTURES'
export const LOAD_CURRENT_CATEGORY = 'LOAD_CURRENT_CATEGORY'
export const LOAD_CART_ITEMS_COUNT = 'LOAD_CART_ITEMS_COUNT'
export const LOAD_CURRENCIES = 'LOAD_CURRENCIES'
export const SET_CURRENT_CURRENCY = 'SET_CURRENT_CURRENCY'
export const SET_CURRENT_CULTURE = 'SET_CURRENT_CULTURE'
export const SET_CURRENT_CULTURE_BY_LANGUAGE = 'SET_CURRENT_CULTURE_BY_LANGUAGE'
export const CLEAR_STATE = 'CLEAR_STATE'
export const GET_ASSETS = 'GET_ASSETS'
export const GET_ASSET = 'GET_ASSET'
export const DELETE_ASSET = 'DELETE_ASSET'
export const UPLOAD_ASSET = 'UPLOAD_ASSET'
