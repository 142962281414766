export const formSend = (formData, postContactsUrl) => {
    if (formData.email.length != 0 && formData.storeID.length != 0) {
        fetch(postContactsUrl, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            },

        }).then((response) => {
            if (response.status === 200) {
                return response.json()
            } else {
                return false
            }
        }).then((data) => {
            console.log(data)
            console.log(JSON.parse(data))
        })
            .catch((e) => console.log('Can’t access ' + postContactsUrl + ' response. Blocked by browser?', e))
        return true
    } else {
        return false
    }
}

export default formSend