/**
 * @function Header - contains all the components of the header section
 *
 * @param {object} categoriesTree - a list of CategoryTreeNodeModel. Each node should contain:
 *    Category {object} - CategoryModel
 *    SubCategories {object} - list of CategoryTreeNodeModel
 * @param {object} currencies - all currencies - should contain:
 *    ID {string} - unique currency id
 *    Symbol {string} - the currency character
 *    Code {string} - the currency name
 * @param {object} currentCurrency - the selected currency - should contain:
 *    ID {string} - unique currency id
 *    Symbol {string} - the currency character
 *    Code {string} - the currency name
 * @param {object} cultures - all cultures - should contain:
 *    ID {string} - unique culture id
 *    Flag {string} - the flag file name
 *    DisplayName {string} - the language name
 * @param currentCulture - the selected culture - should contain:
 *    ID {string} - unique culture id
 *    Flag {string} - the flag file name
 *    DisplayName {string} - the language name
 * @param currentUser - should contains at least FirstName
 */

import React, { Component } from 'react'
import { throttle } from 'throttle-debounce'
import { UStoreProvider } from '@ustore/core'
import Search from './Search'
import CategoriesSidebar from './CategoriesSidebar'
import Profile from './Profile'
import SignOut from './SignOut'
import SignIn from './SignIn'
import Overlay from '$core-components/Overlay'
import Switcher from '$core-components/Switcher'
import Cart from './Cart'
import './Header.scss'
import './HeaderTopBlock.scss'
import {Link, useNavigate} from 'react-router-dom'
import urlGenerator from '$ustoreinternal/services/urlGenerator'
import legacyIframeHandler from '$ustoreinternal/services/legacyIframeHandler'
import { t } from '$themelocalization'
import { CookiesManager, storefrontCookies } from '$ustoreinternal/services/cookies';
import { getVariableValue } from '$ustoreinternal/services/cssVariables'
import theme from '$styles/_theme.scss'
import Icon from '$core-components/Icon'
import 'bootstrap/dist/css/bootstrap.min.css'
import themeContext from '$ustoreinternal/services/themeContext'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import { decodeStringForURL } from '$ustoreinternal/services/utils'
import Favicon from 'react-favicon'
import ProductItem from '../../products/ProductItem'
import Slider from 'react-slick'
import { getIsNGProduct } from '$themeservices'
import Slot from '../../widgets/Slot'

class Header extends Component {
  constructor () {
    super()
    this.header = React.createRef()		// A reference to the main wrapper element
    this.state = {
      drawerOpen: false,						    // Left drawer - opened/closed
      overlayActive: false,	  			    // The overlay - active or not
      lastScrollPos: 0,                 // Latest position of the scroller
      logoImageUrl: require(`$assets/images/logo.png`),
      isDropdownOpen: false,
      isMenuOpen: false,
      isDisplayView: true,
      customProducts: [],
      isCategoriesLoaded: false,
      specDrop: {}
    }
  }

  componentDidMount () {
    window.addEventListener('scroll', this.onScroll)
    throttle(250, this.onScroll)					// Call this function once in 250ms only
    window.addEventListener('resize', this.onResize)
    throttle(250, this.onResize)					// Call this function once in 250ms only

    this.loadProducts()
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onResize)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.customState && this.props.customState.categories && !this.state.isCategoriesLoaded) {
      this.loadProducts()
      this.setState({ isCategoriesLoaded: true })
    }
  }

  async loadProducts () {
    const { customState: { categories } } = this.props
    if (categories && categories.length > 0) {
      const { Products: products } = await UStoreProvider.api.products.getProducts(categories[0].ID)
      this.setState({ customProducts: products })
    }
  }

  // NOTE: this is not supported in SSR
  setLogoImage = () => {
    const variableForLogoImg = window.matchMedia(`(min-width: ${theme.lg})`).matches ? '--logo-image' : '--logo-image-mobile'
    this.setState({ logoImageUrl: getVariableValue(variableForLogoImg, require(`$assets/images/logo.png`), true) })
  }
  changeDisplayView = (isDisplayView) => {
    this.setState({ isDisplayView: isDisplayView })
  }

  onScroll = () => {
    let el = this.header
    let topContentHeight = 0
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop
    let scrollDiff = this.state.lastScrollPos - scrollTop
    this.setState({
      lastScrollPos: scrollTop
    })

    if ((scrollDiff > 0) && (scrollTop > topContentHeight)) {
      // On scrolling up - add position: fixed to the main wrapper
      // so the header will appear at the top of the current view
      el && el.classList && el.classList.add('sticky-slidein')
      document.body.style.marginTop = el.offsetHeight + 'px'
    } else {
      // On scrolling down - remove the scrolling up functionality
      el && el.classList && el.classList.remove('sticky-slidein')
      document.body.style.marginTop = '0px'
    }
  }

  onResize = () => {
    this.setLogoImage()
  }

  popup = () => {
    this.state.isMenuOpen = false
  }

  toggle = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen
    })
  }

  toggleMenu = () => {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen
    })
  }

  drawerStateChange (open) {
    this.setState({ drawerOpen: open })
    this.setState({ overlayActive: open })

    // if (open) {
    //   document.body.style.overflow = 'hidden'
    // } else {
    //   document.body.style.overflow = 'auto'
    // }
  }

  burgerClicked () {
    this.drawerStateChange(true)
  }

  overlayClicked () {
    this.drawerStateChange(false)
  }

  getFlagFromCode (languageCode) {
    return `${languageCode}.svg`
  }

  checkSpecDrop (spec, i) {
    if (this.state.specDrop[spec] != undefined) {
      if (this.state.specDrop[spec][i]) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  toggleSpecDrop (spec, i, mode) {
    let specDrop = this.state.specDrop

    if (mode == 'big-mode' &&
        specDrop[spec] &&
        specDrop[spec] != undefined &&
        specDrop[spec].length > 0) {

      specDrop[spec].map((item, index) => (
          specDrop[spec][index] = index != i ? false : specDrop[spec][index]
      ))
    }

    if (i != 'close') {
      if (specDrop[spec] != undefined) {
        if (specDrop[spec][i] != 'undefined') {
          specDrop[spec][i] = !specDrop[spec][i]
        } else {
          specDrop[spec] = []
          specDrop[spec][i] = true
        }
      } else {
        specDrop[spec] = []
        specDrop[spec][i] = true
      }
    } else {
      specDrop[spec] = []
    }

    this.setState({ specDrop })
  }

  getKey () {
    if (this.getKeyNum) {
      this.getKeyNum++
    } else {
      this.getKeyNum = 1
    }
    const key = 'gk-' + this.getKeyNum
    return key
  }

  render () {
    // console.log(this.state.drawerOpen,'drawerOpen')

    if (!this.props.customState) {
      return null
    }
    const { customState: { categoriesTree, userOrdersSummary }, currencies, cultures, currentCulture, currentUser, currentCurrency, navigate } = this.props
    const currenciesViewModel = currencies && currencies.map(({ ID, Symbol, Code }) => ({
      ID, sign: Symbol, value: Code
    }))
    const culturesViewModel = cultures && cultures.map(({ ID, CountryCode, Name }) => ({
      ID, icon: this.getFlagFromCode(CountryCode), value: Name
    }))

    const currencySelected = (selected) => {
      const selectedCurrency = currencies.find(i => i.ID === selected)
      UStoreProvider.state.culture.setCurrentCurrency(selectedCurrency)
      themeContext.set('currencyFriendlyID', selectedCurrency.FriendlyID)
      CookiesManager.setCookie({ key: storefrontCookies.currencyID, value: selectedCurrency.FriendlyID, days: 30 })
      CookiesManager.setCookie({ key: storefrontCookies.currencyGUID, value: selected, days: 30 })
      legacyIframeHandler.postMessage({
        type: '@CHANGE_CURRENCY',
        data: selectedCurrency.FriendlyID
      })
    }

    const goToSpecials = () => {
      const { categories } = this.props.customState
      const { FriendlyID, Name } = categories[0]
      const defaultURL = urlGenerator.get({ page: 'category', id: FriendlyID, name: decodeStringForURL(Name) })
      this.props.navigate(defaultURL)
      this.toggleMenu()
    }


    const cultureSelected = (selected) => {
      const selectedCulture = cultures.find(i => i.ID === selected)
      const pathWithNewLangugageCode = window.location.pathname.replace(/\/[a-z]{2}-[A-Za-z]{2}\//, `/${selectedCulture.LanguageCode}/`)
      const searchString = window.location.search
      const hashString = window.location.hash
      CookiesManager.setCookie({ key: storefrontCookies.language, value: selectedCulture.LanguageCode, days: 30 })
      window.location.replace(pathWithNewLangugageCode + searchString + hashString)
    }

    const sidebarRedirect = (pageParams) => {
      this.drawerStateChange(false)
      navigate(urlGenerator.get(pageParams))
    }

    const variableForLogoImg = window.matchMedia(`(min-width: ${theme.lg})`).matches ? '--logo-image' : '--logo-image-mobile'
    const currentLogo = getVariableValue(variableForLogoImg, require(`$assets/images/logo.png`), true)
    const popupBanners = [
      {
        style: {
          background: 'url(' + (
              getVariableValue('--popup-banner-1-background', require(`$assets/images/Lamb-and-Bluebird.jpg`), true)
          ) + ') center / cover no-repeat'
        },

        desc: getVariableValue('--popup-banner-1-desc'),
        title: getVariableValue('--popup-banner-1-title')
      },
      {
        style: {
          background: 'url(' + (
              getVariableValue('--popup-banner-2-background', require(`$assets/images/Lamb-and-Bluebird.jpg`), true)
          ) + ') center / cover no-repeat'
        },

        desc: getVariableValue('--popup-banner-2-desc'),
        title: getVariableValue('--popup-banner-2-title')
      },
    ]
    const phoneTitle = getVariableValue('--skin1-contacts-phone-title', '')
    const contactNumber = getVariableValue('--skin1-homepage-contactPage-number')
    const workDays = getVariableValue('--skin1-contacts-workdays', '')
    const page1 = getVariableValue('--skin1-page1', '')
    const page1Link = getVariableValue('--skin1-page1Link', '')
    const page2 = getVariableValue('--skin1-page2', '')
    const page2Link = getVariableValue('--skin1-page2Link', '')
    const page3 = getVariableValue('--skin1-page3', '')
    const page3Link = getVariableValue('--skin1-page3Link', '')
    const page4 = getVariableValue('--skin1-page4', '')
    const page4Link = getVariableValue('--skin1-page4Link', '')
    const page5 = getVariableValue('--skin1-page5', '')
    const page5Link = getVariableValue('--skin1-page5Link', '')
    const favicon = getVariableValue('--skin1-favicon', require(`$assets/images/favicon.png`), true)
    const settingsSlider = {
      slidesToShow: 2,
      infinite: false
    }
    const selected = this.state.selectedCategory
    const { securityToken, storeID, classicUrl, languageCode } = themeContext.get()

    return (
        <div>
          <Favicon url={[favicon]}/>
          <div className='header-wrapper' ref={(ref) => this.header = ref}>
            <div className='header-top-block'>
              <div className='container'>
                <div className='header-top-block-inner'>
                  <div className='contacts'>
                    <span>{workDays}</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className='call'>{phoneTitle}:</span>
                    <div className='header-number'>{contactNumber}</div>
                  </div>
                  <div className='register'>

                    <div className='right-currency'>
                      {currenciesViewModel && currenciesViewModel.length > 0 &&
                          <Switcher
                              className="currency"
                              items={currenciesViewModel}
                              selected={currentCurrency && currenciesViewModel.find((element) => { return currentCurrency.ID === element.ID })}
                              label={t('Header.Currency')}
                              onSelected={currencySelected}/>
                      }
                      {culturesViewModel && culturesViewModel.length > 0 &&
                          <Switcher
                              className="culture"
                              items={culturesViewModel}
                              selected={currentCulture && culturesViewModel.find((element) => { return currentCulture.ID === element.ID })}
                              label={t('Header.Language')}
                              onSelected={cultureSelected}/>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='header-middle-block'>
              <div className='header'>
                <Slot name="header_above" />
                <div className='header-stripe' draweropen={`${this.state.drawerOpen}`}>
                  <div className='container'>
                    <div className="logo-wrapper">
                      <div className="menu-icon-container" onClick={this.burgerClicked.bind(this)}>
                        <Icon name="menu-24px.svg" width="40px" height="40px" className="menu-icon"/>
                      </div>
                      <Link to={urlGenerator.get({ page: 'home' })}>
                        <a>
                          <div className="logo-container">
                            {currentLogo && <img className="logo" src={currentLogo} alt="logo"/>}
                          </div>
                        </a>
                      </Link>
                    </div>
                    <div className='categories big-popup'>
                      <Dropdown
                          isOpen={this.state.isMenuOpen}
                          toggle={this.toggleMenu}
                      >
                        <DropdownToggle
                            tag='div'
                            data-toggle='dropdown'
                        >
                          <div className=''>
                            <span>
                              {t('xmp_theme_collage_products')}
                          </span>
                          </div>
                        </DropdownToggle>
                        <DropdownMenu>
                          <div className="line-1">
                            <div className="row">
                              <div className="col-lg-7 wrap-list-categories">
                                <div className="row">
                                  {
                                      categoriesTree && categoriesTree.map((item, i1) => {
                                        return (
                                            <div key={i1} className="col-lg-4">
                                              <div className={'collage-menu-item'}>
                                                <Link key={i1} to={urlGenerator.get({
                                                  page: 'category',
                                                  id: item.Category.FriendlyID,
                                                  name: decodeStringForURL(item.Category.Name)
                                                })}>
                                            <span onClick={() => this.popup()} className="p-title p-link right-arrow">
                                              {item.Category.Name}
                                            </span>
                                                </Link>
                                                { item.SubCategories && item.SubCategories.length !== 0 ?
                                                    <span className={this.checkSpecDrop('dp-1-'+ i1, i1) ? "arrow-opn active" : "arrow-opn"} onClick={() => this.toggleSpecDrop('dp-1-'+ i1, i1)}>&rsaquo;</span>
                                                    : <></>
                                                }
                                              </div>
                                              { this.checkSpecDrop('dp-1-'+ i1, i1) ?
                                                  <div className="sublist">
                                                    {
                                                        item.SubCategories && item.SubCategories.length !== 0 &&
                                                        item.SubCategories.map((item, i2) => {
                                                          if (i2 > 6) { return false }
                                                          return (
                                                              <Link key={i2} to={urlGenerator.get({
                                                                page: 'category',
                                                                id: item.Category.FriendlyID,
                                                                name: decodeStringForURL(item.Category.Name)
                                                              })}>
                                                                <div key={i2} className='nested-item'>
                                                      <span onClick={() => this.popup()} id={item.Category.FriendlyID}>
                                                        <a className="link">{item.Category.Name}</a>
                                                      </span>
                                                                </div>
                                                              </Link>
                                                          )
                                                        })
                                                    }
                                                  </div>
                                                  : <></> }
                                            </div>
                                        )
                                      })
                                  }
                                </div>
                              </div>
                              <div className="col-lg-5">
                                <span className="p-title arrows-slide">{t('xmp_theme_collage_specials')}</span>
                                <Slider ref={c => (this.slider = c)} {...settingsSlider}>
                                  {this.state.customProducts &&
                                      this.state.customProducts.map((model) => {
                                        if (model) {
                                          return (
                                              <div key={model.ID} className="mini-product">
                                                <ProductItem
                                                    key={model.ID}
                                                    model={model} detailed
                                                    productNameLines="2"
                                                    descriptionLines="2"
                                                    url={getIsNGProduct(model) ?
                                                        urlGenerator.get({
                                                          page: 'products',
                                                          id: model.FriendlyID,
                                                          name: decodeStringForURL(model.Name)
                                                        })
                                                        :
                                                        urlGenerator.get({
                                                          page: 'product',
                                                          id: model.FriendlyID,
                                                          name: decodeStringForURL(model.Name)
                                                        })
                                                    }
                                                />
                                              </div>
                                          )
                                        }
                                      })
                                  }
                                </Slider>
                              </div>
                            </div>
                            <Link to={urlGenerator.get({ page: 'AllCategories' })}>
                            <span className="p-title p-allcat">
                              <a className="link">{t('xmp_theme_collage_all_categories')}</a>
                            </span>
                            </Link>
                          </div>
                          <div className="line-2">
                            <div className="row">
                              <div className="col-lg-6">
                                <div onClick={goToSpecials} className="p-banner p-banner-1" style={popupBanners[0].style}>
                                  <div className="p-banner-inner">
                                    <span className="p-baner-desc">{popupBanners[0].desc}</span>
                                    <span className="p-baner-title">{popupBanners[0].title}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div onClick={goToSpecials} className="p-banner p-banner-2" style={popupBanners[1].style}>
                                  <div className="p-banner-inner">
                                    <span className="p-baner-desc">{popupBanners[1].desc}</span>
                                    <span className="p-baner-title">{popupBanners[1].title}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </DropdownMenu>
                      </Dropdown>
                      {page1Link.length === 0
                          ? null
                          : <span onClick={
                            () => {
                              window.open(page1Link)
                            }}>
                          {page1}
                          </span>
                      }
                      {page2Link.length === 0
                          ? null
                          : <span onClick={
                            () => {
                              window.open(page2Link)
                            }}>
                          {page2}
                          </span>
                      }
                      {page3Link.length === 0
                          ? null
                          : <span onClick={
                            () => {
                              window.open(page3Link)
                            }}>
                          {page3}
                          </span>
                      }
                      {page4Link.length === 0
                          ? null
                          : <span onClick={
                            () => {
                              window.open(page4Link)
                            }}>
                          {page4}
                          </span>
                      }
                      {page5Link.length === 0
                          ? null
                          : <span onClick={
                            () => {
                              window.open(page5Link)
                            }}>
                          {page5}
                          </span>
                      }
                      <span> {<Link key={0} to={urlGenerator.get({ page: 'about-us' })}><a className="link"
                                                                                           key={0}>{t('xmp_theme_about_us')}</a></Link>}</span>
                      <span> {<Link key={1} to={urlGenerator.get({ page: 'contacts' })}><a className="link"
                                                                                           key={1}>{t('xmp_theme_contact_us')}</a></Link>}</span>

                    </div>
                    <div className="right-icons">
                      <Search/>
                      <Cart/>
                      {currentUser && <Profile currentUser={currentUser} userOrdersSummary={userOrdersSummary}/>}
                    </div>
                    <div className="drawer-wrapper">
                      {currenciesViewModel &&
                          <Switcher
                              className="currency"
                              items={currenciesViewModel}
                              selected={currentCurrency && currenciesViewModel.find((element) => { return currentCurrency.ID === element.ID })}
                              label={t('Header.Currency')}
                              onSelected={currencySelected}/>
                      }
                      {culturesViewModel &&
                          <Switcher
                              className="culture"
                              items={culturesViewModel}
                              selected={currentCulture && culturesViewModel.find((element) => { return currentCulture.ID === element.ID })}
                              label={t('Header.Language')}
                              onSelected={cultureSelected}/>
                      }
                      {
                          categoriesTree &&
                          <CategoriesSidebar categoriesTree={categoriesTree} onRedirect={sidebarRedirect}/>
                      }
                      {currentUser && currentUser.IsAnonymous ? <SignIn showTitle={false}/> : <SignOut
                          currentUser={currentUser}/>}
                      <Search/>
                    </div>
                    <Overlay isActive={this.state.overlayActive} overlayClicked={this.overlayClicked.bind(this)}/>
                  </div>
                </div>
                <Slot name="header_below" />
              </div>
            </div>
          </div>

        </div>
    )
  }
}


function addHookTo(Component) {
  function CompWithHook(props) {
    const navigate = useNavigate();

    return <Component {...props} navigate={navigate} />;
  }

  return CompWithHook;
}

export default addHookTo(Header)

