import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension'
import thunkMiddleware from 'redux-thunk'
import defaultState from './defaultState'
import reducer from './reducer'

export const initStore = (initialState = defaultState) => {
  // the following lines are not duplicate
  // the build will select the correct line according to the type of the build
  // ==== DO NOT DELETE ====
  return createStore(reducer, initialState, composeWithDevTools(applyMiddleware(thunkMiddleware)))
  return createStore(reducer, initialState, applyMiddleware(thunkMiddleware))
}
