
class Config {
  set (obj) {
    this.config = obj
    this.configInit = true
  }

  getValue (key) {
    return this.config[key]
  }

  isInit () {
    return this.configInit
  }

  get () {
    return this.config
  }
}

export default new Config()
