import React, { useRef, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import './Tooltip.scss';

const Tooltip = (props) => {
  const { text, className, placement, arrow, maxLine } = props;

  const getElementRef = useCallback((node) => {
    elementRef.current = node;
  }, []);
  const getTitleRef = useCallback((node) => {
    titleRef.current = node;
  }, []);

  const elementRef = useRef(null);
  const titleRef = useRef(null);

  const [isTargetElement, setIsTargetElement] = useState(false);

  useEffect(() => {
    const checkTargetElement = () => {
      const isTargetElement = elementRef.current && (titleRef.current.offsetHeight < titleRef.current.scrollHeight);
      setIsTargetElement(isTargetElement);
    }

    checkTargetElement(); // check on mount

    // check again on resize
    window.addEventListener('resize', checkTargetElement);
    return () => {
      window.removeEventListener('resize', checkTargetElement);
    };
  }, []);


  return (
      <div
          ref={getElementRef}
          className={`defaultStylesTooltip ${className} placement-${placement}`}
      >
        <div className={`tooltip-title lines-${maxLine}`} ref={getTitleRef} dangerouslySetInnerHTML={ { __html: text } }/>
        {isTargetElement && <div className='tooltip-text' ref={getElementRef}>
          <span className={`arrow-${arrow}`}/>
          <div dangerouslySetInnerHTML={ { __html: text } } />
        </div>}
      </div>
  );
};

Tooltip.defaultProps = {
  text: '',
  className: '',
  placement: 'top',
  arrow: true,
  maxLine: 2
}


export default Tooltip;