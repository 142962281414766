import deepcopy from 'deepcopy'

export const convertObjectToQueryString = (obj) =>
  Object.entries(obj).map(
    ([key, value]) => key && value ? `${key}=${encodeURIComponent(value)}` : ''
  ).filter(s => !!s).join('&')

export const merge = (o1, o2) => {
  const o = deepcopy(o1)
  for (let n in o2) {
    if (typeof o2[n] !== 'object' || o2[n] === null) {
      o[n] = o2[n]
    } else if (Array.isArray(o2[n])) {
      o[n] = [...o2[n]]
    } else {
      o[n] = o[n] ? merge(o[n], o2[n]) : merge({}, o2[n])
    }
  }
  return o
}

export const parseStorePreferences = (storePreferences) => {
  let parsedStorePreferences = {}
  try {
    parsedStorePreferences = (storePreferences || 'cartSorting=date').split(';').reduce((acc, curr) => {
      const [key, value] = curr.split('=')
      acc[key] = value
      return acc
    }, {})
  } catch (e) {
    console.error(e)
  }
  return parsedStorePreferences
}

export const stringifyStorePreferences = (storePreferences = {}) => {
  let stringifiedStorePreferences = ''
  try {
    stringifiedStorePreferences = Object.entries(storePreferences).map(
      ([key, value]) => `${key}=${value}`
    ).join(';')
  } catch (e) {
    console.error(e)
  }
  return stringifiedStorePreferences
}
