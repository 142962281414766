import contextService from '../services/context'
import logger from '../services/logger'

function createHeaders (options) {
  // get parameters from coreStateParams to init data in header
  const token = contextService.getValue('securityToken')
  const contentLanguage = contextService.getValue('languageCode')

  if (!options.static) {
    const headers = Object.assign({}, {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': contentLanguage
    })

    if (options.auth) {
      Object.assign(headers, { 'Authorization': `uStore ${token}` })
    }

    return headers
  }

  return {}
}

async function formatResponse (callOptions, response) {
  try {
    if (callOptions.download) {
      return response.blob()
    }

    if (callOptions.json) {
      return await response.json()
    }
    return await response.text()
  } catch (e) {
    logger.error('Error on formatting response : ', callOptions, response)
    return response
  }
}

const http = ['get', 'post', 'put', 'delete'].reduce(function (map, method) {
  map[method] = async function (url, requestBody, options) {
    const callOptions = Object.assign({
      auth: true,
      json: true,
      static: false,
      download: false
    }, options)

    const reqOptions = {
      headers: createHeaders(callOptions),
      method,
      body: requestBody ? JSON.stringify(requestBody) : undefined
    }

    const res = await fetch(`${url}`, reqOptions)
    logger.info('Request to API : ', url, reqOptions)

    const formattedResponse = await formatResponse(callOptions, res)
    logger.info('Response from API : ', JSON.stringify(formattedResponse))

    if (!res.ok) {
      if (formattedResponse.TypeName === 'OperationFailed') {
        throw formattedResponse
      }
      try {
        logger.error('Error in request : ', url, reqOptions)
        if (res.status === 404 && options.download) {
          return null
        }
        if (formattedResponse.TypeName === 'AccessDenied') {
          contextService.getValue('onAccessDenied')()
        } else if (formattedResponse.TypeName === 'ResourceNotAvailable.Store') {
          contextService.getValue('onStoreNotAvailable')()
        } else contextService.getValue('onGeneralError')()
        throw formattedResponse
      } catch (error) {
        // logger.info('General Error')
        if (!error || !error.TypeName) contextService.getValue('onGeneralError')()

        throw formattedResponse
      }
    }
    return formattedResponse
  }

  return map
}, {})

export default http
