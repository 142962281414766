import Layout from '../components/layout'
import './Category.scss'
import React, { Component } from 'react'
import urlGenerator from '$ustoreinternal/services/urlGenerator'
import { Link } from 'react-router-dom'
import { t } from '$themelocalization'

class ThankYouPage extends Component {

  constructor (props) {
    super(props)

    this.state = {
      isMobile: false
    }
  }

  componentDidMount () {
    window.scrollTo(0, 0)
  }

  render () {
    return (
      <Layout {...this.props}>
        <div className="container">
          <div>
            <div >

            <span>{t("xmp_theme_thank_you")}</span>
            <div>
              <Link to={urlGenerator.get({ page: 'home' })}>
                <a style={{ color: 'grey' }}>
                  {t("xmp_theme_back_home")}
                  </a>
                </Link> 
              </div>
            </div>
          </div>
        </div>

      </Layout>
    )
  }
}

export default ThankYouPage
