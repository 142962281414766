import contextService from '../services/context'

const httpDeleteFiles = async (url, name) => {
  const token = contextService.getValue('securityToken')

  const myHeaders = new Headers()
  myHeaders.append('Authorization', `uStore ${token}`)

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    body: name,
    redirect: 'follow'
  }

  return fetch(url, requestOptions)
    .then(response => response.json())
    .catch(error => console.log('error', error))
}

export default httpDeleteFiles
