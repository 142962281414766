import React, { useEffect, useState } from 'react'
import {Link, useParams} from 'react-router-dom'

import { t } from '$themelocalization'
import { Slider, ScrollableGallery } from '$core-components'
import { UStoreProvider } from '@ustore/core'
import urlGenerator from '$ustoreinternal/services/urlGenerator'
import { decodeStringForURL } from '$ustoreinternal/services/utils'
import Layout from '../components/layout'
import { getIsNGProduct } from '$themeservices'
import ProductItem from '../components/products/ProductItem'
import CategoryItem from '../components/category/CategoryItem'

import './Search.scss'
import MainProductItem from "../components/products/MainProductItem";
import {Icon} from "../../core-components";

const PRODUCTS_PAGE_SIZE = 8

/**
 * This is the Search page
 * URL : http://<store-domain>/{language-code}/search/{search text}/
 *
 * @param {object} state - the state of the store
 */

const Search = (props) => {
  const searchValue = useParams().id
  const categories = props.customState?.categories
  const [searchResults, setSearchResults] = useState([])
  const [searchResultsCount, setSearchResultsCount] = useState()
  const galleryTitle = searchResultsCount ? t('SearchResults.Count_products', { count: searchResultsCount }) : ''

  useEffect(() => {
    (async () => {
      const { Products: searchResults, Count: searchResultsCount } = await UStoreProvider.api.products.search(searchValue, 1, PRODUCTS_PAGE_SIZE)
      setSearchResults(searchResults)
      setSearchResultsCount(searchResultsCount)
    })()
  }, [searchValue])

  const loadProducts = async () => {
    if (props.customState.searchResults?.length === searchResultsCount) return

    const nextPage = Math.ceil(searchResults.length / PRODUCTS_PAGE_SIZE) + 1
    const { Products: products } = await UStoreProvider.api.products.search(searchValue, nextPage, PRODUCTS_PAGE_SIZE)
    const joinedProducts = searchResults.concat(products)

    UStoreProvider.state.customState.set('searchResults', joinedProducts)
    setSearchResults(joinedProducts)
  }

  if (!props.state || !props.state.currentStore || !props.customState) {
    return null
  }

  function SampleNextArrow (props) {
    const { className, onClick } = props
    return (
        <div className={className}
             style={{ display: 'block', background: 'red' }}
             onClick={onClick}>
          <Icon name="chevron_right-24px.svg" width="23px" height="23px"/>
        </div>
    )
  }

  function SamplePrevArrow (props) {
    const { className, onClick } = props
    return (
        <div className={className}
             style={{ display: 'block', background: 'green' }}
             onClick={onClick}>
          <Icon name="chevron_left-24px.svg" width="23px" height="23px"/>
        </div>
    )
  }

  return <Layout {...props} className='search-ng'>
    {searchResults && searchResultsCount > 0 ?
        <div className="search-results">
          <div className="breadcrumbs">
            <Link to={urlGenerator.get({ page: 'home' })}>
              <a>{t('xmp_theme_collage_home')}</a>
            </Link>
            <span>></span>
            <a>{t('SearchResults.Title')}</a>
          </div>
          <span className="title-page">{t('SearchResults.Title')}</span>
          <ScrollableGallery title={galleryTitle} hasMoreItems={true} onScroll={loadProducts}>
            {searchResults.map((model) => {
              return <MainProductItem
                      key={model.ID}
                      model={model} detailed
                      productNameLines="2"
                      descriptionLines="2"
                      url={getIsNGProduct(model)
                          ? urlGenerator.get({ page: 'products', id: model.FriendlyID, name: decodeStringForURL(model.Name) })
                          : urlGenerator.get({ page: 'product', id: model.FriendlyID, name: decodeStringForURL(model.Name) })
                      }

                  />
            })
            }
          </ScrollableGallery>
        </div>
        :
        (searchResults && !searchResults.length) ?
            <div className="no-results">
              <div className="top-section">
                <div className="title no-results-title">{t('SearchResults.No_results_title')}</div>
                <div className="no-results-subtitle">{t('SearchResults.No_results_subtitle')}</div>
              </div>
              {categories && categories.length > 0 &&
                  <div className="bottom-section slider-search-wrap">
                    <div className="divider"></div>
                    <div className="title bottom-section-title">{t('SearchResults.No_results_bottom_section_title')}</div>
                    <div className="categories-wrapper slider-search">
                      {
                        categories.map((model) => {
                              return <CategoryItem key={model.ID} model={model}
                                                   url={urlGenerator.get({ page: 'category', id: model.FriendlyID, name: decodeStringForURL(model.Name) })} />
                            }
                        )
                      }
                    </div>
                  </div>
              }
            </div> : null
    }
  </Layout>
}

export default Search
