import storeService from './store'
import cultureService from './culture'
import providerState from './providerState'
import contextService from './context'

class InitialState {
  async loadInitialState () {
    // get initialState from API and set it in redux if needed
    // if state already has data these actions will override it
    const currentStore = await storeService.loadCurrentStore()

    await storeService.loadStoreCultures()

    cultureService.setCurrentCultureByLanguage(contextService.getValue('languageCode'))

    await storeService.loadStoreCurrencies()

    if (currentStore?.Attributes?.find((attr) => attr.Name === 'ForceCartAspx' && attr.Value === 'False')) {
      await storeService.loadCartItemsCount()
    }

    const currencyFriendlyID = contextService.getValue('currencyFriendlyID')
    let currentCurrency = providerState.get().currencies.filter(c => c.FriendlyID === parseInt(currencyFriendlyID))[0]

    const currencyID = contextService.getValue('currencyID')

    if (currencyID) {
      currentCurrency = providerState.get().currencies.filter(c => c.ID === currencyID)[0]
    }
    cultureService.setCurrentCurrency(currentCurrency)

    await storeService.loadCurrentUser()

    // raise flag that initial state was loaded in order not to do it again
    providerState.setInitState()
  }
}

export default new InitialState()
