import {
  getUserOrdersSummary,
  addOrderItem,
  getOrderItem,
  updateOrderItem,
  getPriceOrderItem,
  reorder,
  getLastOrder,
  addToCart,
  saveForLater,
  getDeliveryServices,
  getProperties,
  updateProperties,
  updatePropertiesState,
  getRepositoryDetails,
  uploadFile,
  deleteFiles,
  getCartInfo,
  getWishListInfo,
  deleteCartItem,
  clearCart,
  clearWishlist,
  validateCart,
  validateWishList,
  getCartPricingInfo,
  getWishListPricingInfo,
  updateCartItem,
  updateWishListItem,
  updateCart,
  createCartList,
  getListId,
  getListInfo,
  getLists,
  deleteList,
  exportList,
  revertModifications,
  renameList,
  duplicateItem,
  duplicateList,
} from '../api/orders'
import { getTopCategories, getSubCategories, getCategoryIDByFriendlyID, getCategory, getCategoryTree } from '../api/categories'
import {
  getProductThumbnails,
  getProductIDByFriendlyID,
  getProducts,
  getProductByID,
  productType,
  getProductsByIDs,
  search,
  createProofPreview,
  downloadProofPreview,
  getProofPreview,
  createProof,
  downloadProof,
  checkProofStatus,
} from '../api/products'
import { getCurrentStore, getCurrentUser, getCultures, getCurrencies, renewToken, getCartItemsCount, loginBySSOToken, } from '../api/store'
import { getAssets, getAsset, deleteAsset, uploadAsset } from '../api/assets'
import {getActivities} from '../api/activities'

export default {
  activities: {
    getActivities
  },
  categories: {
    getTopCategories,
    getSubCategories,
    getCategoryIDByFriendlyID,
    getCategory,
    getCategoryTree
  },
  orders: {
    getUserOrdersSummary,
    addOrderItem,
    getOrderItem,
    updateOrderItem,
    getPriceOrderItem,
    reorder,
    getLastOrder,
    addToCart,
    saveForLater,
    getDeliveryServices,
    getProperties,
    updateProperties,
    updatePropertiesState,
    getRepositoryDetails,
    uploadFile,
    deleteFiles,
    getCartInfo,
    getWishListInfo,
    deleteCartItem,
    clearCart,
    clearWishlist,
    validateCart,
    validateWishList,
    getCartPricingInfo,
    getWishListPricingInfo,
    updateCartItem,
    updateWishListItem,
    updateCart,
    createCartList,
    getListId,
    getListInfo,
    getLists,
    deleteList,
    exportList,
    revertModifications,
    renameList,
    duplicateItem,
    duplicateList,
  },
  products: {
    getProductIDByFriendlyID,
    getProducts,
    getProductByID,
    productType,
    getProductsByIDs,
    getProductThumbnails,
    search,
    createProofPreview,
    downloadProofPreview,
    getProofPreview,
    createProof,
    downloadProof,
    checkProofStatus,
  },
  store: {
    getCurrentStore,
    getCurrentUser,
    getCultures,
    getCurrencies,
    renewToken,
    getCartItemsCount,
    loginBySSOToken,
  },
  assets: {
    getAssets,
    getAsset,
    deleteAsset,
    uploadAsset
  }

}
