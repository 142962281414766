import './DefaultCategoryItem.scss'
import { Link } from 'react-router-dom'
import LinesEllipsis from 'react-lines-ellipsis'
import React from 'react'

/**
 * This component represents the category in the store
 * It contains the category image and details
 *
 * @param {object} model - the category
 * @param {string} url - the url to redirects when clicking the category
 * @param {string} [className] - the css class to add to main div
 */
const DefaultCategoryItem = ({ model, url, className }) => {

  if (!model) {
    return <div className="default-category-item"/>
  }

  const imageUrl = model.ImageUrl ? model.ImageUrl : require(`$assets/images/default.png`)

  return (
    <div className="col-lg-4 col-md-6">
      <Link to={url}>
        <a className={`default-category-item ${className || ''}`}>
          <div className="default-image-wrapper">
            <img src={imageUrl} alt={'product image'}/>
          </div>
          <div className="default-category-wrap">
            <div className="default-category-name">
              <LinesEllipsis style={{ whiteSpace: 'pre-wrap' }} text={model.Name} maxLine={2} basedOn='words'/>
            </div>
          </div>
        </a>
      </Link>
    </div>
  )
}

export default DefaultCategoryItem
