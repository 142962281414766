import React, {useEffect, useRef} from 'react'
import './ImageLoader.scss'
import { debounce } from 'throttle-debounce'

const ImageLoader = ({isStretched, className, src}) => {

  const image = useRef()

  useEffect(() => {
    const img = image.current
    if (img && img.complete) {
      imageOnLoad()
    }
    if (isStretched) {
      window.addEventListener('resize', imageOnLoad)
    }
    return () => {
      if (isStretched) {
        window.removeEventListener('resize', imageOnLoad)
      }
    }
  }, []);


  const imageOnLoad = debounce(100, () => {
    const img = image.current
    if (img) {
      img.classList.remove('hide')
      img.classList.add('show')
      img.previousSibling.classList.remove('show')
      img.previousSibling.classList.add('hide')
      if (isStretched) {
        let container = img.parentElement
        const borderWidth = parseInt(getComputedStyle(img).borderWidth, 10) * 2;
        let adjustedContainerWidth = container.offsetWidth - borderWidth;
        let adjustedContainerHeight = container.offsetHeight - borderWidth;

        while (adjustedContainerWidth <= 0 || adjustedContainerHeight <= 0) {
          container = container.parentElement
          adjustedContainerWidth = container.offsetWidth - borderWidth;
          adjustedContainerHeight = container.offsetHeight - borderWidth;
        }

        if (img.naturalWidth / img.naturalHeight > adjustedContainerWidth / adjustedContainerHeight) {
          img.style.width = `${adjustedContainerWidth}px`
          img.style.height = 'auto'
        } else {
          img.style.width = 'auto'
          img.style.height = `${adjustedContainerHeight}px`
        }
      }
    }
  })

  const imageOnError = () => {
    const imageElem = image.current
    if (imageElem) {
      imageElem.src = require(`$assets/images/default.png`)
      imageElem.classList.remove('hide')
      imageElem.classList.add('show')
      imageElem.previousSibling.classList.remove('show')
      imageElem.previousSibling.classList.add('hide')
    }
  }

  return (
      <div className={`image-loader ${className ? className : ''}`}>
        <div className="animated loading"/>
        <img
          alt=''
          ref={image}
          src={src}
          onError={imageOnError}
          onLoad={imageOnLoad}
          className="hide"
        />
      </div>
)
}

export default ImageLoader
