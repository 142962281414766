/**
 * This is the Homepage
 * URL : http://<store-domain>/{language-code}/home/
 *
 * @param {object} state - the state of the store
 */
import { UStoreProvider } from '@ustore/core'
import Layout from '../components/layout'
import PromotionItem from '../components/products/PromotionItem'
import urlGenerator from '$ustoreinternal/services/urlGenerator'
import { t } from '$themelocalization'
import './Home.scss'
import React, { Component } from 'react'
import { getVariableValue } from '$ustoreinternal/services/cssVariables'
import theme from '$styles/_theme.scss'
import { throttle } from 'throttle-debounce'
import { decodeStringForURL } from '$ustoreinternal/services/utils'
import '../styles/my-slick.css'
import HomeCategories from '../components/category/HomeCategories'
import { getIsNGProduct } from '$themeservices'
import Icon from '$core-components/Icon'
import Slot from '../components/widgets/Slot'
import Slider from 'react-slick'
import HomeProductItem from '../components/products/HomeProductItem'

class Home extends Component {
  static getDerivedStateFromProps (props, state) {
    if (!(props.state && props.customState)) {
      return null
    }

    const { categories } = props.customState
    //NOTE: this is not supported in SSR
    if (categories && categories.length > 0 && !state.promotionItemButtonUrl.length) {
      const { FriendlyID, Name } = categories[0]
      const defaultURL = urlGenerator.get({ page: 'category', id: FriendlyID, name: decodeStringForURL(Name) })
      if(getVariableValue('--skin1-homepage-carousel-slide-1-button-url') == '/category'){
        return { promotionItemButtonUrl: defaultURL}
      }
      return { promotionItemButtonUrl: getVariableValue('--skin1-homepage-carousel-slide-1-button-url', false, false, true)}
    }
    return null
  }

  constructor (props) {
    super(props)
    this.next = this.next.bind(this)
    this.state = {
      isMobile: false,
      promotionItemButtonUrl: '',
      loadedProducts: [],
      customProducts: [],
      clicked: false,
      clickedProm: false,
      isCategoriesLoaded: false,
    }
  }

  loadProducts = () => {
    const { customState: { categories } } = this.props
    let listFriendlyIDProducts = getVariableValue('--skin1-homepage-best-sellers-products', false)
    if (listFriendlyIDProducts && listFriendlyIDProducts.search(/\d/) != -1) {
      listFriendlyIDProducts = listFriendlyIDProducts.split(/\s*,\s*/)
      listFriendlyIDProducts.map(async (item) => {
        const productID = await UStoreProvider.api.products.getProductIDByFriendlyID(item)
        let product = await UStoreProvider.api.products.getProductByID(productID, false)
        const thumbnails = await UStoreProvider.api.products.getProductThumbnails(productID)

        if(thumbnails && thumbnails.length !== 0){
          product.ImageUrl = thumbnails.Thumbnails[0]['Url'];
        }

        const { customProducts } = this.state

        if (product) {
          customProducts.push(product)
        }

        this.setState({ customProducts })
        this.setState({ clickedProm: true })
      })
    } else {

      if (categories && categories.length > 0) {
        categories.map(async (item, i) => {
          if (i > 0) { return false }
          const { Products: products } = await UStoreProvider.api.products.getProducts(item.ID)
          const { customProducts } = this.state
          if (products && products.length !== 0) {
            products.map((item) => {
              customProducts.push(item)
            })
          }
          this.setState({ customProducts })
          this.setState({ clickedProm: true })
        })
      }

    }
  }

  next = () => {
    this.slider.slickNext()
  }

  test = () => {
    this.setState({ clicked: true })
  }

  layout = () => {
    const layout = getVariableValue('--skin1-layout')
    let homepageWrap
    if (layout === '0' || layout === 0 || layout === '0px') {
      homepageWrap = {
        flexDirection: 'column'
      }
    }
    if (layout === '1' || layout === 1 || layout === '1px') {
      homepageWrap = {
        flexDirection: 'column-reverse'
      }
    }
    return homepageWrap
  }

  componentDidMount () {
    window.addEventListener('resize', this.onResize.bind(this))
    throttle(250, this.onResize)					// Call this function once in 250ms only
    this.onResize()
    setTimeout(() => this.test(), 1500)
    this.layout()
    window.scrollTo(0, 0);
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.customState && this.props.customState.categories && !this.state.isCategoriesLoaded && this.state.clicked) {
      this.loadProducts();
      this.setState({ isCategoriesLoaded: true })
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onResize)
    this.clearCustomState()
  }

  clearCustomState () {
    UStoreProvider.state.customState.delete('homeFeaturedProducts')
    UStoreProvider.state.customState.delete('homeFeaturedCategory')
    UStoreProvider.state.customState.delete('currentProduct')
    UStoreProvider.state.customState.delete('currentOrderItem')
    UStoreProvider.state.customState.delete('currentOrderItemId')
    UStoreProvider.state.customState.delete('currentOrderItemPriceModel')
    UStoreProvider.state.customState.delete('lastOrder')
    UStoreProvider.state.customState.delete('currentProductThumbnails')
  }

  onResize () {
    this.setState({ isMobile: document.body.clientWidth < parseInt(theme.md.replace('px', '')) })
  }

  render () {
    if (!this.props.state) {
      return null
    }

    function SampleNextArrow (props) {
      const { className, onClick } = props
      return (
          <div className={className}
               style={{ display: 'block', background: 'red' }}
               onClick={onClick}>
            <Icon name="chevron_right-24px.svg" width="23px" height="23px"/>
          </div>
      )
    }

    function SamplePrevArrow (props) {
      const { className, onClick } = props
      return (
          <div className={className}
               style={{ display: 'block', background: 'green' }}
               onClick={onClick}>
            <Icon name="chevron_left-24px.svg" width="23px" height="23px"/>
          </div>
      )
    }

    const { customState: { categories } } = this.props

    const promotionItemImageUrl1 = getVariableValue('--skin1-homepage-carousel-slide-1-image', require(`$assets/images/Lamb-and-Bluebird.jpg`), true)
    const promotionItemImageUrl2 = getVariableValue('--skin1-homepage-carousel-slide-2-image', require(`$assets/images/Lamb-and-Bluebird.jpg`), true)
    const promotionItemImageUrl3 = getVariableValue('--skin1-homepage-carousel-slide-3-image', require(`$assets/images/Lamb-and-Bluebird.jpg`), true)
    const promotionItemTitle = getVariableValue('--skin1-homepage-carousel-slide-1-main-text', t('xmp_theme_collage_promotion_item_title'))
    const promotionItemSubtitle = getVariableValue('--skin1-homepage-carousel-slide-1-sub-text', t('xmp_theme_collage_promotion_item_subtitle'))
    const promotionItemButtonText = getVariableValue('--skin1-homepage-carousel-slide-1-button-text')
    const slidesToShow = this.state.customProducts ? (this.state.customProducts.length < 5 ? this.state.customProducts.length : 5) : 0
    const slidesToShow2 = this.state.customProducts ? (this.state.customProducts.length < 3 ? this.state.customProducts.length : 3) : 0
    const slidesToShow3 = this.state.customProducts ? (this.state.customProducts.length < 2 ? this.state.customProducts.length : 2) : 0

    const bestSellerTopLine = getVariableValue('--skin1-best-seller-top-line', '')
    const bestSellerBottomLine = getVariableValue('--skin1-best-seller-bottom-line', '')

    const settingsCatSlider = {
      dots: false,
      speed: 200,
      swipe: false,
      infinite: false,
      slidesToShow: this.state.customProducts ? (this.state.customProducts.length === 1 ? 1 : (slidesToShow + 1)) : 0,
      nextArrow: <SampleNextArrow/>,
      prevArrow: <SamplePrevArrow/>,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: slidesToShow2,
          }
        },
        {
          breakpoint: 770,
          settings: {
            slidesToShow: slidesToShow3,
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    }

    return (
        <Layout {...this.props} className="home">
          <Slot name="homepage_top" />
          <div className='homepage-wrap' style={this.layout()}>
            <div className="promotion-wrapper">
              <PromotionItem
                  promotionItemImageUrl1={promotionItemImageUrl1}
                  promotionItemImageUrl2={promotionItemImageUrl2}
                  promotionItemImageUrl3={promotionItemImageUrl3}
                  title={promotionItemTitle}
                  subTitle={promotionItemSubtitle}
                  buttonText={this.state.clickedProm ? promotionItemButtonText : ''}
                  url={this.state.clickedProm ? this.state.promotionItemButtonUrl : ''}
              />
            </div>
            <div className='container'>
              <HomeCategories categories={categories}/>
            </div>
          </div>
          <div className='container'>
            {this.state.customProducts && this.state.customProducts.length !== 0 ?
                <div className="product-list container">
                  <div className="products-title">{bestSellerTopLine}</div>
                  <div className="semi-title">{bestSellerBottomLine}</div>
                  <div className="row best-seller">
                    <Slider {...settingsCatSlider}>
                      {
                          this.state.customProducts &&
                          this.state.customProducts.map((model) => {
                            if (model) {
                              const hideProduct =
                                  this.state.isMobile &&
                                  model.Attributes &&
                                  model.Attributes.find(attr => attr.Name === 'UEditEnabled' && attr.Value === 'true') !== undefined

                              return (!hideProduct &&
                                  <HomeProductItem
                                      key={model.ID}
                                      model={model}
                                      productNameLines="2"
                                      descriptionLines="2"
                                      url={getIsNGProduct(model) ?
                                          urlGenerator.get({
                                            page: 'products',
                                            id: model.FriendlyID,
                                            name: decodeStringForURL(model.Name)
                                          })
                                          :
                                          urlGenerator.get({
                                            page: 'product',
                                            id: model.FriendlyID,
                                            name: decodeStringForURL(model.Name)
                                          })
                                      }
                                  />)
                            }
                          })
                      }
                    </Slider>
                  </div>
                </div>
                : <></>}
          </div>
          <Slot name="homepage_bottom" />
        </Layout>
    )
  }
}

Home.getInitialProps = async function (ctx) {
  const maxInPage = 200
  const { Count } = await UStoreProvider.api.categories.getTopCategories(1, maxInPage)
  if (Count === 0) {
    return { homeFeaturedProducts: null, homeFeaturedCategory: null }
  }

  const page = Math.ceil(Count / maxInPage)
  const { Categories } = await UStoreProvider.api.categories.getTopCategories(page, maxInPage)

  if (Categories.length === 0) {
    return { homeFeaturedProducts: null, homeFeaturedCategory: null }
  }

  const homeFeaturedCategory = Categories[Categories.length - 1]
  const { Products: homeFeaturedProducts } = await UStoreProvider.api.products.getProducts(homeFeaturedCategory.ID, 1)
  return { homeFeaturedProducts, homeFeaturedCategory }
}

export default Home
