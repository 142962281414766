import { useEffect, useState} from 'react'
import { useNavigate} from 'react-router-dom'
import Inventory from './Inventory'
import Price from './Price'
import ProductItemQuantity from './ProductItemQuantity'
import UnitsOfMeasure from './UnitsOfMeasure'
import { Tooltip, Icon, ImageLoader, LoadingDots } from '$core-components'
import { UStoreProvider } from '@ustore/core'
import { t } from '$themelocalization'
import './ProductItem.scss'
import './HomeProductItem.scss'

const HomeProductItem = (props) => {
  const navigate = useNavigate()
  const [currentOrderItem, setCurrentOrderItem] = useState(null)
  const [isPriceCalculating, setIsPriceCalculating] = useState(false)
  const [calculatedPriceModel, setCalculatedPriceModel] = useState(null)
  const [addToCartShowSuccess, setAddToCartShowSuccess] = useState(false)
  const [quantity, setQuantity] = useState(null)
  const [isQuantityValid, setIsQuantityValid] = useState(true)
  const [addToCartShowSuccessTimer, setAddToCartShowSuccessTimer] = useState(null)
  let {descriptionLines, productNameLines, model, url, detailed, className} = props

  const onQuantityChange = async (newQuantity, isValid) => {
    if (isValid) {
      setQuantity(newQuantity)
      if (model.HasPricing) {
        setIsPriceCalculating(true)
        const calculatedPriceModel = await onCalculatePrice(newQuantity)
        setIsPriceCalculating(false)
        setIsQuantityValid(true)
        setCalculatedPriceModel(calculatedPriceModel)
      } else {
        setIsQuantityValid(true)
      }
    } else {
      setIsQuantityValid(false)
    }
  }

  const onCalculatePrice = async (newQuantity) => {
    const { model } = props
    if (model.MinimumPrice === null) return
    const orderItemForApi = currentOrderItem || await UStoreProvider.api.orders.addOrderItem(model.ID)

    const priceModel = await UStoreProvider.api.orders.getPriceOrderItem(orderItemForApi.ID, { ...orderItemForApi, Quantity: newQuantity })
    setCalculatedPriceModel(priceModel.Price)
    setCurrentOrderItem(orderItemForApi)
    return priceModel.Price
  }

  const addToCart = async () => {
    if (isQuantityValid) {
      const { model } = props
      const orderItemForApi = currentOrderItem || await UStoreProvider.api.orders.addOrderItem(model.ID)
      // call the update order api if quantity is updated
      if (quantity) {
        const updated = {
          ...orderItemForApi,
          Quantity: quantity
        }

        await UStoreProvider.api.orders.updateOrderItem(orderItemForApi.ID, updated)
      }
      await UStoreProvider.api.orders.addToCart(orderItemForApi.ID)

      if (UStoreProvider.state.get()['currentStore']?.Attributes?.find((attr) => attr.Name === 'ForceCartAspx' && attr.Value === 'False')) {
        await UStoreProvider.state.store.loadCartItemsCount()
      }

      return true
    }

    return false
  }

  const onAddToCartClick = async () => {
    const success = await addToCart()

    if (success) {
      setAddToCartShowSuccess(true)
      setCurrentOrderItem(null)
      setQuantity(null)
      setAddToCartShowSuccessTimer(setTimeout(() => {
        setAddToCartShowSuccess(false)
        setCalculatedPriceModel(null)
      }, 3000))
    }
  }

  const onClick = (url) => {
    window.scrollTo(0, 0)
    if (typeof url === "string") {
      navigate(url)
    }
  }

  useEffect(() => {
    return () => {
      clearTimeout(addToCartShowSuccessTimer)
    }
  }, [addToCartShowSuccessTimer])

  if (!model) {
    return null
  }

  productNameLines = productNameLines ? productNameLines : 2
  descriptionLines = descriptionLines ? descriptionLines : 4

  const imageUrl = (model && model.ImageUrl) ? model.ImageUrl : require(`$assets/images/default.png`)
  const productNameAndCatalog = model.CatalogNumber && model.CatalogNumber.trim().length > 0 ? `${model.Name} / ${model.CatalogNumber}` : model.Name
  const showQuickAddToCart = model.Configuration && model.Configuration.AllowQuickAddToCart
  const priceModelToDisplay = calculatedPriceModel || model.MinimumPrice
  const isMinimumPrice = !calculatedPriceModel && !showQuickAddToCart
  const quantityToShow = quantity || model.MinimumQuantity

  return (
      <div onClick={() => onClick(url)} className='home-product-item'>
        <div className="home-image-wrapper">
          <ImageLoader className="image" src={imageUrl}/>
        </div>
        <div className='home-product-wrapper'>
          <Inventory model={model.Inventory} minQuantity={model.MinimumQuantity}/>
          <div style={{ height: 70, marginTop: 15, marginBottom: 15, width: 170 }}>
            <div className="home-product-description">
              {props.categoryName}
            </div>
            <div className="home-product-name" style={{maxHeight: `${productNameLines * 1.5}em`}} onClick={() => onClick(url)}>
              <Tooltip placement="top" text={productNameAndCatalog} maxLine={productNameLines}/>
            </div>
            {
              (model.MinimumPrice) ?
                  (
                      <div>
                        <div className="home-product-price">
                          <Price model={model.MinimumPrice} isMinimumPrice={true}/>
                        </div>
                        <div className="home-product-units">
                          <UnitsOfMeasure minQuantity={model.MinimumQuantity} model={model.Unit}/>
                        </div>
                      </div>
                  ) : ''
            }</div>
        </div>
      </div>
  )
}


export default HomeProductItem
