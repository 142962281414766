import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import legacyIframeHandler from '$ustoreinternal/services/legacyIframeHandler'

function RouteChangeListener() {
  const location = useLocation();

  useEffect(() => {
    // This callback will be invoked every time the location (route) changes
    legacyIframeHandler.handleRoute(location.pathname + location.search)

  }, [location]);  // The effect will re-run when the location changes

  return null;  // This component doesn't render anything
}

export default RouteChangeListener;
