/**
 * A component to display a product's photo, title and more info
 *
 * @param {object} model - ProductModel containing data of the product
 * @param {number} productNameLines - max lines of product name (default is 2)
 * @param {number} descriptionLines - max lines of short description (default is 4)
 * @param {boolean} detailed - controls the display - if true the description of the product should show, otherwise hide
 * @param {string} url - the url to redirect to when clicking the product
 * @param {string} [className] - a class name to place on the product element
 */

import React, { Component } from 'react'
import './ProductItem.scss'
import Price from './Price'
import UnitsOfMeasure from "./UnitsOfMeasure"
import Inventory from "./Inventory"
import HTMLLinesEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import ImageLoader from '$core-components/ImageLoader'
import { t } from '$themelocalization'
import { UStoreProvider } from '@ustore/core'
import './MainProductItem.scss'
import {useNavigate} from "react-router-dom";
import urlGenerator from '$ustoreinternal/services/urlGenerator'

// using this ResponsiveEllipsis will handle responsive changes to the lineEllipsis component.
const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLLinesEllipsis)



class ProductItem extends Component {

  constructor(props) {
    super(props)

    this.addToCartShowSuccessTimer = null;

    this.state = {
      currentOrderItem: null,
      isPriceCalculating: false,
      calculatedPriceModel: null,
      addToCartShowSuccess: false,
      quantity: null,
      isQuantityValid: true
    }
  }

  componentWillUnmount() {
    clearTimeout(this.addToCartShowSuccessTimer)
  }

  onQuantityChange = async (value, isValid) => {
    const { model } = this.props

    if (isValid) {
      if (model.HasPricing) {
        this.setState({ isPriceCalculating: true })
        await this.onCalculatePrice(value)
        this.setState({ isPriceCalculating: false, quantity: value, isQuantityValid: true })
      }
      else {
        this.setState({ quantity: value, isQuantityValid: true })
      }
    }
    else {
      this.setState({ quantity: value, isQuantityValid: false })
    }
  }

  onCalculatePrice = async (value) => {
    const { model } = this.props
    if (model.MinimumPrice === null) return
    const currentOrderItem = this.state.currentOrderItem ? this.state.currentOrderItem : await UStoreProvider.api.orders.addOrderItem(model.ID)
    if (currentOrderItem.Quantity)
      currentOrderItem.Quantity = value

    const priceModel = await UStoreProvider.api.orders.getPriceOrderItem(currentOrderItem.ID, currentOrderItem)

    this.setState({ calculatedPriceModel: priceModel.Price, currentOrderItem: currentOrderItem })

  }

  addToCart = async () => {
    if (!!this.state.isQuantityValid) {
      const { model } = this.props
      const currentOrderItem = this.state.currentOrderItem ? this.state.currentOrderItem : await UStoreProvider.api.orders.addOrderItem(model.ID)
      // call the update order api if quantity is updated
      if (this.state.quantity) {
        const updated = {
          ...currentOrderItem,
          Quantity: this.state.quantity
        }

        await UStoreProvider.api.orders.updateOrderItem(currentOrderItem.ID, updated)
      }
      await UStoreProvider.api.orders.addToCart(currentOrderItem.ID)
      return true
    }

    return false
  }

  onAddToCartClick = async () => {
    const success = await this.addToCart()

    if (success) {
      this.setState({ addToCartShowSuccess: true, currentOrderItem: null, quantity: null })

      this.addToCartShowSuccessTimer = setTimeout(() => {
        this.setState({ addToCartShowSuccess: false, calculatedPriceModel: null })
      }, 3000)
    }
  }

  render() {
    let { descriptionLines, productNameLines, model, url, detailed, className } = this.props

    if (!model) {
      return null
    }

    productNameLines = productNameLines ? productNameLines : 2
    descriptionLines = descriptionLines ? descriptionLines : 4

    const onClick = (url) => {
      if (typeof url === "string") {
        if (window.location.href.indexOf("products") > -1) {
          this.props.navigate(urlGenerator.get({ page: 'home' }))
          setTimeout(()=>{
            this.props.navigate(url)
          },1)
        } else {
          this.props.navigate(url)
        }
      }
    }

    const imageUrl = (model && model.ImageUrl) ? model.ImageUrl : require(`$assets/images/default.png`)
    const productNameAndCatalog = model.CatalogNumber && model.CatalogNumber.trim().length > 0 ? `${model.Name} / ${model.CatalogNumber}` : model.Name
    const showQuickAddToCart = model.Configuration && model.Configuration.AllowQuickAddToCart
    const priceModelToDisplay = this.state.calculatedPriceModel ? this.state.calculatedPriceModel : model.MinimumPrice
    const isMinimumPrice = !this.state.calculatedPriceModel && !showQuickAddToCart
    const quantity = this.state.quantity ? this.state.quantity : model.MinimumQuantity

    return (
        <div onClick={() => onClick(url)} className='slide-product-item'>
          <div className="slide-image-wrapper">
            <ImageLoader className="image" src={imageUrl}/>
          </div>
          <div className='slide-product-wrapper'>
            <Inventory model={model.Inventory} minQuantity={model.MinimumQuantity}/>
            <div className={'collage-product-item'}>
              <div className="slide-product-name" style={{ maxHeight: `${productNameLines * 1.5}em` }}>
                <ResponsiveHTMLEllipsis style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
                                        unsafeHTML={productNameAndCatalog}
                                        maxLine={productNameLines} basedOn='letters'/>
              </div>
              {
                (model.MinimumPrice) ?
                    (
                        <div>
                          <div className="slider-product-price">
                            <Price model={model.MinimumPrice} isMinimumPrice={true}/>
                          </div>
                          <div className="main-product-units">
                            <UnitsOfMeasure minQuantity={model.MinimumQuantity} model={model.Unit}/>
                          </div>
                        </div>
                    ) : ''
              }</div>
            <div className="prod-custom-button">{t('xmp_theme_add_to_cart')}</div>
          </div>
        </div>
    )
  }
}

function addHookTo(Component) {
  function CompWithHook(props) {
    const navigate = useNavigate();

    return <Component {...props} navigate={navigate} />;
  }

  return CompWithHook;
}

export default addHookTo(ProductItem)
