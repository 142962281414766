import Layout from '../components/layout'
import './Category.scss'
import React, { Component } from 'react'
import { decodeStringForURL } from '$ustoreinternal/services/utils'
import urlGenerator from '$ustoreinternal/services/urlGenerator'
import { t } from '$themelocalization'
import { Link } from 'react-router-dom'
import DefaultCategoryItem from '../components/category/DefaultCategoryItem'

class AllCategories extends Component {

  constructor (props) {
    super(props)

    this.state = {
      isMobile: false
    }
  }

  render () {
    const { customState: { categories } } = this.props
    return (
      <Layout {...this.props} className="category">
        <div className='container'>
          <div className="breadcrumbs">
            <Link key={1} to={urlGenerator.get({ page: 'home' })}>
              <a key={1}>Home</a>
            </Link>
              <span>></span>
            <Link key={0} to={urlGenerator.get({ page: 'AllCategories' })}><a key={0}>{t('xmp_theme_collage_categories')}</a></Link></div>
        </div>
        <div className='container'>
          <div className="title-page">{t('xmp_theme_collage_categories')}</div>
          <div className='wrap-cards'>
            <div className='row'>
              {categories && categories.length > 0 && 

              categories.map((model) => {
                  return <DefaultCategoryItem key={model.ID} model={model}
                                              url={urlGenerator.get({
                                                page: 'category',
                                                id: model.FriendlyID,
                                                name: decodeStringForURL(model.Name)
                                              })}/>
                }
              )
              }
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AllCategories
