import contextService from '../services/context'

const httpUpload = async (url, uploadedValue) => {
  const token = contextService.getValue('securityToken')
  const contentLanguage = contextService.getValue('languageCode')

  const myHeaders = new Headers()
  const formData = new FormData()
  myHeaders.append('Authorization', `uStore ${token}`)
  myHeaders.append('Accept-Language', contentLanguage)

  uploadedValue.map((item) => formData.append(encodeURIComponent(item.name), item, item.name))

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formData,
    redirect: 'follow'
  }

  const promises = await fetch(url, requestOptions)
    .then(response => response.json())
    .catch(error => console.log('error uploading', error))
  return Promise.all([promises])
}

export default httpUpload
