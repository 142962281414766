import './HomeCategories.scss'
import React, { Component } from 'react'
import urlGenerator from '$ustoreinternal/services/urlGenerator'
import { decodeStringForURL } from '$ustoreinternal/services/utils'
import {useNavigate} from 'react-router-dom'
import Slider from 'react-slick'
import Icon from '$core-components/Icon'

class HomeCategories extends Component {
  constructor (props) {
    super(props)
    this.state = { width: 0 }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener("resize", this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
      window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
  }

  updateWindowDimensions() {
      this.setState({ width: window.innerWidth});
  }

  render () {
    const { categories } = this.props
    /*
    let classesInner = (categories && (categories.length == 4 || categories.length == 5)) ? 'ic-special ' : ' ';
    classesInner += (categories && (categories.length < 6 )) ? 'ic-1-row ' : ' ';
    classesInner += categories ? 'collage-inner-wrap ' : ' '; */

    //let classesInner = categories ? 'collage-inner-wrap collage-items-' + categories.length : '';

    const goToCategory = (item) => {
      console.log(item,'item')
      this.props.navigate(urlGenerator.get({
        page: 'category',
        id: item.FriendlyID,
        name: decodeStringForURL(item.Name)
      }))
    }


    function SampleNextArrow (props) {
      const { className, onClick } = props
      return (
        <div className={className}
             style={{ display: 'block', background: 'red' }}
             onClick={onClick}>
          <Icon name="chevron_right-24px.svg" width="23px" height="23px"/>
        </div>
      )
    }

    function SamplePrevArrow (props) {
      const { className, onClick } = props
      return (
        <div className={className}
             style={{ display: 'block', background: 'green' }}
             onClick={onClick}>
          <Icon name="chevron_left-24px.svg" width="23px" height="23px"/>
        </div>
      )
    }


    const sliderSettings = {
      dots: false,
      speed: 200,
      swipe: false,
      loop: false,
      arrows: false,
      infinite: false,
      nextArrow: <SampleNextArrow/>,
      prevArrow: <SamplePrevArrow/>,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            arrows: true
          }
        }]
    }

    let array_size = this.state.width <= 900 ? 1 : 6;
    let categories_sliced = [];

    if(categories && categories.length !== 0){
      for (let i = 0; i <categories.length; i += array_size) {
        categories_sliced.push(categories.slice(i, i + array_size));
      }
    }

    return (
      <div className='collage'>
        {categories_sliced && categories_sliced.length > 0 &&
          <Slider {...sliderSettings}>
          {
            categories_sliced.map((item, index) => {
              let classesInner = item ? 'collage-inner-wrap collage-items-' + item.length : '';
              return(
                <div key={index} className='wrap-item-collage'>
                  <div key={index} className={classesInner}>
                    {item && item.length > 0 &&
                    item.map((model, index) => {
                      const imageUrl = (model && model.ImageUrl) ? model.ImageUrl : require(`$assets/images/default.png`)
                      return (
                          <div className='item-collage' onClick={()=>goToCategory(model)} key={index}>
                            <div className='iс-inner'>
                              <div className="ic-content">
                                <div className="ic-background" style={{background: `url(${imageUrl}) center / cover no-repeat`}}>
                                  <div className="ic-text">
                                    <span className="ic-title">{model.Name}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      )
                    })
                    }
                  </div>
                </div>
              )
            })
          }
          </Slider>
        }
      </div>
    )
  }
}

function addHookTo(Component) {
  function CompWithHook(props) {
    const navigate = useNavigate();

    return <Component {...props} navigate={navigate} />;
  }

  return CompWithHook;
}

export default addHookTo(HomeCategories)