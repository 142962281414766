import providerState from './providerState'

class Binding {
  constructor () {
    this.webComponents = {}
    this.componentId = 1
    this.state = providerState

    // subscribe to store changes and set model attribute on all
    // connected components
    this.state.store.subscribe(() => {
      setTimeout(() => {
        const model = JSON.stringify(this.state.get())
        Object.values(this.webComponents).forEach(c => c.setAttribute('model', model))
      }, 0)
    })
  }

  // register a new component set an binding id to allow removing the
  // component when unregistering
  // listening to the action event and dispatching it to redux store.
  register (component) {
    const model = JSON.stringify(this.state.get())
    component.ustoreBindingId = this.componentId
    component.addEventListener('action', ({ data }) => this.state.dispatch(data))
    this.webComponents[this.componentId] = component
    component.setAttribute('model', model)
    this.componentId++
  }

  unregister (ustoreBindingId) {
    delete this.webComponents[ustoreBindingId]
  }
}

export default new Binding()
