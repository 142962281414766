import { UStoreProvider } from '@ustore/core'
import Layout from '../components/layout'
import './Category.scss'
import urlGenerator from '$ustoreinternal/services/urlGenerator'
import { t } from '$themelocalization'
import theme from '$styles/_theme.scss'
import { throttle } from 'throttle-debounce'
import React, { Component } from 'react'
import { getIsNGProduct } from '$themeservices'
import { decodeStringForURL } from '$ustoreinternal/services/utils'
import '../styles/my-slick.css'
import Icon from '$core-components/Icon'
import MainProductItem from '../components/products/MainProductItem'
import TitleProductItem from '../components/products/TitleProductItem'
import CustomCategoryItem from '../components/category/CustomCategoryItem'
import Slider from 'react-slick'
import Slot from '../components/widgets/Slot'
import { Link } from 'react-router-dom'

const PRODUCTS_SIZE_ON_LOAD = 20
const PRODUCTS_SIZE_PAGE = 8

/**
 * This is the category page
 * URL : http://<store-domain>/{language-code}/category/{category friendly ID}/
 *
 * @param {object} state - the state of the store
 */

class Category extends Component {
  static getDerivedStateFromProps (props, state) {
    if ((props.customState && props.customState.categoryFeaturedProducts) && (state.prevProps.categoryProducts.length === 0
        || state.prevProps.currentCategoryId !== props.customState.currentCategory.ID)) {

      const newState = {
        categoryProducts: props.customState.categoryFeaturedProducts,
        count: props.customState.categoryProductsCount,
        page: 0,
        prevProps: {
          categoryProducts: props.customState.categoryFeaturedProducts,
          currentCategoryId: props.customState.currentCategory.ID
        }
      }
      return {
        ...state,
        ...newState
      }
    }
    return null
  }

  constructor (props) {
    super(props)

    this.state = {
      isMobile: false,
      categoryProducts: [],
      count: 0,
      page: 0,
      displayTitles: localStorage ? this.localHost(localStorage.getItem('displayTitles')) : false,
      displayList: localStorage ? this.localHost(localStorage.getItem('displayList')) : false,
      prevProps: {
        categoryProducts: [],
        currentCategoryId: 0
      }
    }
  }

  localHost = (display) => {
    if (display === 'true') {
      return true
    }
    if (display === 'false') {
      return false
    }
  }

  autoCheck = () => {
    if (this.localHost(localStorage.getItem('displayTitles')) === undefined) {
      this.setState({ displayTitles: true })
    }
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    window.addEventListener('resize', this.onResize.bind(this))
    throttle(250, this.onResize)					// Call this function once in 250ms only
    this.onResize()
    this.autoCheck()
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.customState.categoryID !== prevProps.customState.categoryID){
      this.clearCustomState()
      const {Products: products} = await UStoreProvider.api.products.getProducts(this.props.customState.categoryID, 1, PRODUCTS_SIZE_PAGE)
      UStoreProvider.state.customState.set('categoryFeaturedProducts', products)
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onResize)
    this.clearCustomState()
  }

  clearCustomState () {
    UStoreProvider.state.customState.delete('categoryFeaturedProducts')
    UStoreProvider.state.customState.delete('categoryProductsCount')
    UStoreProvider.state.customState.delete('currentCategory')
    UStoreProvider.state.customState.delete('subCategories')
    UStoreProvider.state.customState.delete('currentProduct')
    UStoreProvider.state.customState.delete('currentOrderItem')
    UStoreProvider.state.customState.delete('currentOrderItemId')
    UStoreProvider.state.customState.delete('currentOrderItemPriceModel')
    UStoreProvider.state.customState.delete('lastOrder')
    UStoreProvider.state.customState.delete('currentProductThumbnails')
    UStoreProvider.state.customState.delete('categoryID')
  }

  async loadProducts() {
    if (!this.props.customState) {
      return null
    }

    const {customState: {currentCategory, categoryFeaturedProducts}} = this.props
    const nextPage = Math.ceil(categoryFeaturedProducts.length / PRODUCTS_SIZE_PAGE) + 1
    const {Products: products} = await UStoreProvider.api.products.getProducts(currentCategory.ID, nextPage, PRODUCTS_SIZE_PAGE)
    const joinedProducts = categoryFeaturedProducts.concat(products)

    UStoreProvider.state.customState.set('categoryFeaturedProducts', joinedProducts)
    this.setState({page: this.state.page + 1})
  }

  onResize () {
    this.setState({ isMobile: document.body.clientWidth < parseInt(theme.md.replace('px', '')) })
  }

  handleClick = () => {
    this.setState({ page: this.state.page + 1 })
  }

  changeTitleView = () => {
    this.setState({ displayTitles: true, displayList: false })
    localStorage.setItem('displayTitles', 'true')
    localStorage.setItem('displayList', 'false')
  }
  changeListView = () => {
    this.setState({ displayList: true, displayTitles: false })
    localStorage.setItem('displayTitles', 'false')
    localStorage.setItem('displayList', 'true')
  }

  activeTreeCheck (item, currentCategory) {
    if(currentCategory){
      if(currentCategory.FriendlyID === item.Category.FriendlyID){
        return true;
      } else {
        if(item.SubCategories && item.SubCategories.length !== 0){
          let bool = false;
          item.SubCategories.map((itm) => {
            bool = this.activeTreeCheck(itm, currentCategory) ? true : bool;
          })
          return bool;
        } else {
          return false;
        }
      }
    }
  }

  generateTree (data, currentCategory) {
    return (data && data.length !== 0) ? data.map((item, i) => {
      const itemInner = item.Category;
      const active = this.activeTreeCheck(item, currentCategory);
      return active ? (
          <span key={i}>
                  &nbsp;<span>&gt;</span>&nbsp;
            <Link key={i} to={urlGenerator.get({
              page: 'category',
              id: itemInner.FriendlyID,
              name: decodeStringForURL(itemInner.Name)
            })}>
                  <a key={i}>{itemInner.Name}</a>
                  </Link>
            {this.generateTree(item.SubCategories, currentCategory)}
              </span>
      ) : null
    }) : <></>
  }

  render () {
    function SampleNextArrow (props) {
      const { className, onClick } = props
      return (
          <div className={className}
               style={{ display: 'block', background: 'red' }}
               onClick={onClick}>
            <Icon name="chevron_right-24px.svg" width="23px" height="23px"/>
          </div>
      )
    }

    function SamplePrevArrow (props) {
      const { className, onClick } = props
      return (
          <div className={className}
               style={{ display: 'block', background: 'green' }}
               onClick={onClick}>
            <Icon name="chevron_left-24px.svg" width="23px" height="23px"/>
          </div>
      )
    }

    if (!this.props.customState) {
      return null
    }

    const { customState: { categoryFeaturedProducts, categoryProductsCount, subCategories, currentCategory } } = this.props
    const { categoryProducts, page, count } = this.state
    const galleryTitle =
        categoryProductsCount ?
            subCategories ?
                t('Category.Count_featured_products', { count: categoryProductsCount }) :
                t('Category.Count_products', { count: categoryProductsCount })
            : ''
    const { customState: { categories, categoriesTree } } = this.props
    const hasMoreItems = categoryFeaturedProducts && categoryFeaturedProducts.length < categoryProductsCount
    let iconStyle1 = {
      opacity: '0.4'
    }
    let iconStyle2 = {
      opacity: '0.4'
    }
    if (this.state.displayTitles === true) {
      iconStyle1 = {
        opacity: '1'
      }
    }
    if (this.state.displayList === true) {
      iconStyle2 = {
        opacity: '1'
      }
    }
    if (this.state.displayTitles === false) {
      iconStyle1 = {
        opacity: '0.4'
      }
    }
    if (this.state.displayList === false) {
      iconStyle2 = {
        opacity: '0.4'
      }
    }
    const productStyle = {
      marginTop: 30,
      marginBottom: 60,
    }
    const slidesToShow = subCategories ? (subCategories.length < 3 ? subCategories.length : 3) : 0
    const slidesToShow2 = subCategories ? (subCategories.length < 2 ? subCategories.length : 2) : 0

    const settingsCatSlider = {
      dots: false,
      speed: 500,
      loop: false,
      slidesToShow: slidesToShow,
      infinite: false,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow/>,
      prevArrow: <SamplePrevArrow/>,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: slidesToShow2,
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    }
    let width = ''
    if (subCategories && subCategories.length === 2) {
      width = '50%'
    }
    if (subCategories && subCategories.length === 3) {
      width = '33.3%'
    }
    if (subCategories && subCategories.length === 4) {
      width = '25%'
    }

    const subcategory = {
      width: 'calc(' + width + ' - 30px)',
      margin: '0 15px'
    }

    if (currentCategory && subCategories && categoryFeaturedProducts) {

      return (
          <Layout {...this.props} className="category">
            <Slot name="category_top" />
            <div className='container categories-outer-wrap'>
              <div className='categories-outer-wrap'>
                <div className="breadcrumbs">
                  <span>
                  <Link to={urlGenerator.get({ page: 'home' })}>
                  <a>{t('xmp_theme_collage_home')}</a>
                  </Link>
                  <span>&gt;</span>
                  <Link to={urlGenerator.get({ page: 'AllCategories' })}><a>{t('xmp_theme_collage_categories')}</a></Link>
                    {this.generateTree(categoriesTree, currentCategory)}
                  </span>
                </div>
                <div className="category-title-page">{currentCategory && currentCategory.Name}</div>
                <div className="products-title">{currentCategory && currentCategory.Description}</div>
                <div>
                  {subCategories && subCategories.length >= 5 ?
                      <div className="slider-categories-top">
                        <Slider {...settingsCatSlider}>
                          {subCategories && subCategories.map((model) => {
                            if (model) {
                              return (
                                  <div key={model.ID} className='product-item-middle'>
                                    <CustomCategoryItem isBottom={false} subCategories={subCategories} width={width}
                                                        key={model.ID} model={model}
                                                        url={urlGenerator.get({
                                                          page: 'category',
                                                          id: model.FriendlyID,
                                                          name: decodeStringForURL(model.Name)
                                                        })}/>
                                  </div>
                              )
                            }
                          })
                          }
                        </Slider>
                      </div>
                      : <></>
                  }
                  <div className='products-middle-wrap'>
                    <div className='products-middle-category'>
                      {subCategories && subCategories.length < 5 &&
                          subCategories.map((model) => {
                            if (model) {
                              return (
                                  <div key={model.ID} style={subcategory} className='product-item-middle'>
                                    <CustomCategoryItem isBottom={false} subCategories={subCategories} key={model.ID}
                                                        model={model}
                                                        url={urlGenerator.get({
                                                          page: 'category',
                                                          id: model ? model.FriendlyID : '',
                                                          name: decodeStringForURL(model.Name)
                                                        })}/>
                                  </div>
                              )
                            } else {
                              return <></>
                            }
                          })
                      }
                    </div>
                  </div>
                </div>
                <div className='handler'>

                      <div className='icons-handler'>
                        <div style={iconStyle1}>
                        <Icon onClick={this.changeTitleView} className='handler-icon blue-icon'
                              name="view_module-24px.svg"
                              width="30px"
                              height="30px"/>
                        </div>
                        <div style={{ width: 15 }}/>
                        <div style={iconStyle2}>
                        <Icon onClick={this.changeListView}
                              className='handler-icon blue-icon'
                              name="view_list-24px.svg"
                              width="30px"
                              height="30px"/>
                        </div>
                      </div>
                    </div>

                {this.state.displayList
                    ? <div>
                      <div className='title-parrent-products'>
                        {categoryFeaturedProducts &&
                            categoryFeaturedProducts.map((model) => {
                              const hideProduct =
                                  this.state.isMobile &&
                                  model.Attributes &&
                                  model.Attributes.find(attr => attr.Name === 'UEditEnabled' && attr.Value === 'true') !== undefined
                              if (model) {
                                return !hideProduct &&
                                    <div key={model.ID} style={productStyle}>
                                      <TitleProductItem
                                          key={model.ID}
                                          model={model} detailed
                                          productNameLines="2"
                                          descriptionLines="2"
                                          url={getIsNGProduct(model) ?
                                              urlGenerator.get({
                                                page: 'products',
                                                id: model.FriendlyID,
                                                name: decodeStringForURL(model.Name)
                                              })
                                              :
                                              urlGenerator.get({
                                                page: 'product',
                                                id: model.FriendlyID,
                                                name: decodeStringForURL(model.Name)
                                              })
                                          }

                                      />
                                    </div>
                              } else {
                                return <></>
                              }
                            })
                        }
                      </div>
                      {
                          categoryProductsCount > (PRODUCTS_SIZE_PAGE + (page * PRODUCTS_SIZE_PAGE)) &&
                          <div onClick={this.loadProducts.bind(this)} className='btn-wrap'>
                            <div className="btn-load">{t('xmp_theme_load_more')}</div>
                          </div>
                      }
                    </div>
                    : null
                }
                {this.state.displayTitles
                    ? <div>
                      <div className='row'>
                        {categoryFeaturedProducts &&
                            categoryFeaturedProducts.map((model) => {
                              const hideProduct =
                                  this.state.isMobile &&
                                  model.Attributes &&
                                  model.Attributes.find(attr => attr.Name === 'UEditEnabled' && attr.Value === 'true') !== undefined
                              if (model) {
                                return !hideProduct &&
                                    <div key={model.ID} className="col-lg-3 col-sm-6 col-md-6 col-xs-12 parent-wrap">
                                      <MainProductItem
                                          key={model.ID}
                                          model={model} detailed
                                          productNameLines="2"
                                          descriptionLines="2"
                                          url={getIsNGProduct(model) ?
                                              urlGenerator.get({
                                                page: 'products',
                                                id: model.FriendlyID,
                                                name: decodeStringForURL(model.Name)
                                              })
                                              :
                                              urlGenerator.get({
                                                page: 'product',
                                                id: model.FriendlyID,
                                                name: decodeStringForURL(model.Name)
                                              })
                                          }

                                      />
                                    </div>
                              } else {
                                return <></>
                              }
                            })
                        }
                      </div>
                      {
                          categoryProductsCount > (PRODUCTS_SIZE_PAGE + (page * PRODUCTS_SIZE_PAGE)) &&
                          <div onClick={this.loadProducts.bind(this)} className='btn-wrap'>
                            <div className="btn-load">{t('xmp_theme_load_more')}</div>
                          </div>
                      }
                    </div>
                    : null
                }
              </div>
            </div>
            <Slot name="category_bottom" />
          </Layout>)
    } else {
      return (
          <Layout {...this.props} className="category">
            <Slot name="category_top" />
            <div className='wrap-cards'>
              {t('xmp_theme_loading')}
            </div>
            <Slot name="category_bottom" />
          </Layout>

      )
    }

  }
}

Category.getInitialProps = async (ctx) => {
  const {query: {id: categoryFriendlyID}} = ctx

  if (!categoryFriendlyID || categoryFriendlyID === undefined) return {}

  const categoryID = await UStoreProvider.api.categories.getCategoryIDByFriendlyID(categoryFriendlyID)
  const currentCategory = await UStoreProvider.api.categories.getCategory(categoryID)

  const {Categories: subCategories} = await UStoreProvider.api.categories.getSubCategories(categoryID, 1, 20)
  const {
    Products: categoryFeaturedProducts,
    Count: categoryProductsCount
  } = await UStoreProvider.api.products.getProducts(categoryID, 1, PRODUCTS_SIZE_PAGE)

  return {
    categoryFeaturedProducts,
    categoryProductsCount,
    currentCategory,
    subCategories,
    categoryID
  }

}

export default Category
