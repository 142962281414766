/**
 * A component to display a product's photo, title and more info
 *
 * @param {object} model - ProductModel containing data of the product
 * @param {number} productNameLines - max lines of product name (default is 2)
 * @param {number} descriptionLines - max lines of short description (default is 4)
 * @param {boolean} detailed - controls the display - if true the description of the product should show, otherwise hide
 * @param {string} url - the url to redirect to when clicking the product
 * @param {string} [className] - a class name to place on the product element
 */

import React, {Component} from 'react'
import './ProductItem.scss'
import Price from './Price'
import UnitsOfMeasure from "./UnitsOfMeasure"
import Inventory from "./Inventory"
import HTMLLinesEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import ImageLoader from '$core-components/ImageLoader'
import ProductItemQuantity from './ProductItemQuantity'
import Icon from '$core-components/Icon'
import {t} from '$themelocalization'
import LoadingDots from '$core-components/LoadingDots'
import {UStoreProvider} from '@ustore/core'
import './MainProductItem.scss'
import {useNavigate} from "react-router-dom";
import {Tooltip} from '$core-components'

// using this ResponsiveEllipsis will handle responsive changes to the lineEllipsis component.
const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLLinesEllipsis)


class MainProductItem extends Component {

    constructor() {
        super()

        this.addToCartShowSuccessTimer = null;

        this.state = {
            currentOrderItem: null,
            isPriceCalculating: false,
            calculatedPriceModel: null,
            addToCartShowSuccess: false,
            quantity: null,
            isQuantityValid: true
        }
    }

    componentWillUnmount() {
        clearTimeout(this.addToCartShowSuccessTimer)
    }

    onQuantityChange = async (value, isValid) => {
        const {model} = this.props

        if (isValid) {
            if (model.HasPricing) {
                this.setState({isPriceCalculating: true})
                await this.onCalculatePrice(value)
                this.setState({isPriceCalculating: false, quantity: value, isQuantityValid: true})
            } else {
                this.setState({quantity: value, isQuantityValid: true})
            }
        } else {
            this.setState({quantity: value, isQuantityValid: false})
        }
    }

    onCalculatePrice = async (value) => {
        const {model} = this.props
        const currentOrderItem = this.state.currentOrderItem ? this.state.currentOrderItem : await UStoreProvider.api.orders.addOrderItem(model.ID)

        if (currentOrderItem.Quantity)
            currentOrderItem.Quantity = value

        const priceModel = await UStoreProvider.api.orders.getPriceOrderItem(currentOrderItem.ID, currentOrderItem)

        this.setState({calculatedPriceModel: priceModel.Price, currentOrderItem: currentOrderItem})

    }

    addToCart = async () => {
        if (!!this.state.isQuantityValid) {
            const {model} = this.props
            const currentOrderItem = this.state.currentOrderItem ? this.state.currentOrderItem : await UStoreProvider.api.orders.addOrderItem(model.ID)
            // call the update order api if quantity is updated
            if (this.state.quantity) {
                const updated = {
                    ...currentOrderItem,
                    Quantity: this.state.quantity
                }

                await UStoreProvider.api.orders.updateOrderItem(currentOrderItem.ID, updated)
            }
            await UStoreProvider.api.orders.addToCart(currentOrderItem.ID)
            return true
        }

        return false
    }

    onAddToCartClick = async () => {
        const success = await this.addToCart()

        if (success) {
            this.setState({addToCartShowSuccess: true, currentOrderItem: null, quantity: null})

            this.addToCartShowSuccessTimer = setTimeout(() => {
                this.setState({addToCartShowSuccess: false, calculatedPriceModel: null})
            }, 3000)
        }
    }

    render() {
        let {descriptionLines, productNameLines, model, url, detailed, className} = this.props

        if (!model) {
            return null
        }
        const onClick = (url) => {
            if (typeof url === "string") {
                this.props.navigate(url)
            }
        }

        productNameLines = productNameLines ? productNameLines : 2
        descriptionLines = descriptionLines ? descriptionLines : 4

        const imageUrl = (model && model.ImageUrl) ? model.ImageUrl : require(`$assets/images/default.png`)
        const productNameAndCatalog = model.CatalogNumber && model.CatalogNumber.trim().length > 0 ? `${model.Name} / ${model.CatalogNumber}` : model.Name
        const showQuickAddToCart = model.Configuration && model.Configuration.AllowQuickAddToCart
        const priceModelToDisplay = this.state.calculatedPriceModel ? this.state.calculatedPriceModel : model.MinimumPrice
        const isMinimumPrice = !this.state.calculatedPriceModel && !showQuickAddToCart
        const quantity = this.state.quantity ? this.state.quantity : model.MinimumQuantity
        const quantityToShow = quantity || model.MinimumQuantity
        return (
            <div className={`slide-product-item ${className ? className : ''}`}
                 data-qaautomationinfo={model.FriendlyID}>
                <div className="slide-image-wrapper" onClick={() => onClick(url)}>
                    <ImageLoader className="image" src={imageUrl}/>
                </div>
                <div className="slide-product-name" style={{maxHeight: `${productNameLines * 1.5}em`}}
                     onClick={() => onClick(url)}>
                    <Tooltip placement="top" text={productNameAndCatalog} maxLine={productNameLines}/>
                </div>
                {detailed &&
                    <div className="slide-product-description" style={{maxHeight: `${descriptionLines * 1.5}em`}}>
                        <Tooltip placement="bottom" text={model.ShortDescription} maxLine={descriptionLines}/>
                    </div>}
                <Inventory model={model.Inventory} minQuantity={model.MinimumQuantity}/>
                {model.HasPricing && priceModelToDisplay && <div>
                    <div className="product-units">
                        <UnitsOfMeasure minQuantity={model.MinimumQuantity} model={model.Unit}
                                        isMinimumPrice={isMinimumPrice}/>
                    </div>
                    <div className="product-price">
                        {this.state.isPriceCalculating ?
                            <LoadingDots/> : <Price model={priceModelToDisplay} isMinimumPrice={isMinimumPrice}/>
                        }
                    </div>
                </div>}
                <div className="anchor-flex-end"></div>
                {showQuickAddToCart && <div className='add-to-cart'>
                    {!this.state.addToCartShowSuccess && <div className='add-to-cart-controls'>
                        <div className='add-to-cart-product-quantity'>
                            <ProductItemQuantity
                                supportsInventory={true}
                                onQuantityChange={this.onQuantityChange}
                                productModel={model}
                                orderModel={{Quantity: quantityToShow}}
                            />
                        </div>
                        <div className='add-to-cart-button-wrapper'>
                            <div className="title-custom-button"
                                 onClick={() => this.onAddToCartClick()}>{t('xmp_theme_add_to_cart')}</div>
                        </div>
                    </div>
                    }
                    {this.state.addToCartShowSuccess &&
                        <div className='add-to-cart-success'>
                            <div>{t('ProductItem.Added_successfully_message')}
                                <span className='success-checkmark-icon-wrapper'>
                  <Icon name="checkmark.svg" width="20px" height="20px" className="success-checkmark-icon"/>
                </span>
                            </div>

                        </div>
                    }
                </div>
                }
            </div>
        )
    }
}

function addHookTo(Component) {
    function CompWithHook(props) {
        const navigate = useNavigate();

        return <Component {...props} navigate={navigate}/>;
    }

    return CompWithHook;
}

export default addHookTo(MainProductItem)
